import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import { color, COLOR } from 'styles/colors';
import useAnimation from 'hooks/useAnimation';
import Modal from 'components/atoms/Modal';
import Overlay from 'components/atoms/Overlay';

const modalFadeIn = keyframes`
	from {
		opacity: 0;
		transform: translate(-50%, calc(-50% + 50px));
	}

	to {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
`;

const overlayFadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const ModalWrapper = styled.div`
  position: fixed;
  width: 830px;
  border-radius: 20px;
  text-align: left;
  top: 50%;
  left: 50%;
  background-color: ${color[COLOR.WHITE]};
  opacity: 1;
  z-index: 29;
  animation: ${css`
    ${modalFadeIn} 300ms linear forwards;
  `};
`;

const AnimatedOverlay = styled(Overlay)`
  animation: ${css`
    ${overlayFadeIn} 300ms linear forwards;
  `};
`;

export default function MainModal(props) {
  const { toClose, isOpen, children } = props;
  const [shouldRender] = useAnimation(isOpen);

  function closeModal() {
    toClose();
  }

  return shouldRender ? (
    <>
      <ModalWrapper>
        <Modal>{children}</Modal>
      </ModalWrapper>
      <AnimatedOverlay onClick={closeModal} isActive={isOpen} />
    </>
  ) : null;
}

MainModal.propTypes = {
  toClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
