import { METHOD } from 'constants/method';

export default async function requestDomainDetails(domainNo) {
  const res = await fetch('/_domain/getDomain', {
    method: METHOD.POST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: new URLSearchParams({ domainNo }),
  });

  return res.json();
}
