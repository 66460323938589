import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: ${({ width }) => width};
  padding: 16px;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-family: ${({ fontFamily }) => fontFamily};
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.33, 0, 0.2, 1);

  &:disabled {
    cursor: not-allowed;
  }
`;

export const WhiteButton = styled(Button)`
  height: 50px;
  color: ${color[COLOR.PURPLE][500]};
  letter-spacing: -0.5px;
  background-color: ${color[COLOR.WHITE]};
  border: 1px solid ${color[COLOR.GRAY][300]};
`;

export const PurpleButton = styled(Button)`
  width: ${({ width }) => width};
  height: 50px;
  color: ${color[COLOR.WHITE]};
  letter-spacing: -0.5px;
  background-color: ${color[COLOR.PURPLE][500]};
  border-color: ${color[COLOR.PURPLE][500]};

  &:hover:enabled {
    background-color: ${color[COLOR.PURPLE][600]};
  }
`;

export const LargePurpleButton = styled(PurpleButton)`
  width: ${({ width }) => width || '200px'};
  height: 60px;
  letter-spacing: 0;
`;

export const ShortWidePurpleButton = styled(PurpleButton)`
  width: 160px;
  height: 45px;
  line-height: 19px;
  letter-spacing: 0;
  vertical-align: middle;
`;

export const GrayButton = styled(Button)`
  width: ${({ width }) => width};
  height: 50px;
  color: ${({ disabled }) => (disabled ? color[COLOR.GRAY][500] : color[COLOR.PURPLE][500])};
  letter-spacing: -0.5px;
  background-color: ${color[COLOR.GRAY][200]};
  border-color: ${color[COLOR.GRAY][200]};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? null : '#EAEAF3')};
  }
`;

export const LargeGrayButton = styled(GrayButton)`
  width: 215px;
  height: 60px;
  letter-spacing: 0;
`;

export const ShortWideGrayButton = styled(GrayButton)`
  width: 160px;
  height: 45px;
  line-height: 19px;
  letter-spacing: 0;
  vertical-align: middle;
`;

export const RedButton = styled(Button)`
  width: ${({ width }) => width};
  height: 50px;
  color: ${color[COLOR.WHITE]};
  letter-spacing: -0.5px;
  background-color: ${color[COLOR.RED][500]};
  border-color: ${color[COLOR.RED][500]};

  &:hover {
    background-color: #d93250;
  }
`;

export const LargeRedButton = styled(RedButton)`
  width: 215px;
  height: 60px;
  letter-spacing: 0;
`;

export const SplitGrayLeftButton = styled(Button)`
  width: 115px;
  height: 45px;
  color: ${color[COLOR.PURPLE][500]};
  letter-spacing: 0;
  vertical-align: middle;
  background-color: ${color[COLOR.GRAY][200]};
  border-color: ${color[COLOR.GRAY][200]};
  border-radius: 10px 0 0 10px;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? color[COLOR.GRAY][200] : color[COLOR.GRAY][250]};
  }
`;

export const SplitGrayRightButton = styled(Button)`
  width: 44;
  height: 45px;
  margin-left: 1px;
  color: ${color[COLOR.PURPLE][500]};
  vertical-align: middle;
  background-color: ${color[COLOR.GRAY][200]};
  border-color: ${color[COLOR.GRAY][200]};
  border-radius: 0 10px 10px 0;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? color[COLOR.GRAY][200] : color[COLOR.GRAY][250]};
  }
`;
