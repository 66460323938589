export const color = {
  purple: {
    100: '#F4F4FF',
    200: '#DBDAF4',
    300: '#B6B5E8',
    500: '#6D6BD1',
    600: '#5553C0',
  },
  red: {
    100: '#FDEDEE',
    200: '#FBDCDE',
    300: '#F6A7AD',
    500: '#ED4E5C',
    600: '#E23A49',
  },
  blue: {
    100: '#ECF7FD',
    200: '#C4E8FC',
    300: '#98D2F2',
    400: '#74CDFF',
    500: '#45ADE8',
  },
  green: {
    100: '#EBF8F6',
    500: '#3ABEA6',
  },
  yellow: {
    100: '#FFF5E2',
    500: '#FFC85B',
  },
  gray: {
    50: '#FBFBFC',
    100: '#F7F7FA',
    200: '#F0F0F5',
    250: '#EDEDF2',
    300: '#E2E2EA',
    350: '#DDD',
    400: '#B9B9C5',
    500: '#8B8B9E',
    600: '#6C6C7D',
  },
  black: '#222',
  softBlack: 'rgba(34, 34, 34, 0.06)',
  white: '#FFF',
};

export const COLOR = {
  PURPLE: 'purple',
  RED: 'red',
  BLUE: 'blue',
  GREEN: 'green',
  YELLOW: 'yellow',
  GRAY: 'gray',
  BLACK: 'black',
  SOFTBLACK: 'softBlack',
  WHITE: 'white',
};
