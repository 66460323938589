export default function DomainThumbnail() {
  return (
    <svg width={48} height={48} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width={48} height={48} rx={4} fill='#45ADE8' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.093 13.908c-4.154 1.194-7.252 4.884-7.567 9.342h4.49a17.905 17.905 0 013.077-9.342zm2.157-.382a16.426 16.426 0 00-3.733 9.724h8.966a16.426 16.426 0 00-3.733-9.724 10.652 10.652 0 00-1.5 0zm3.657.382a17.905 17.905 0 013.078 9.342h4.489c-.315-4.458-3.413-8.148-7.567-9.342zm7.567 10.842h-4.49a17.905 17.905 0 01-3.077 9.343c4.154-1.195 7.252-4.885 7.567-9.343zm-9.724 9.724a16.426 16.426 0 003.733-9.724h-8.966a16.426 16.426 0 003.733 9.724 10.65 10.65 0 001.5 0zm-3.657-.381a17.905 17.905 0 01-3.078-9.343h-4.489c.315 4.458 3.413 8.148 7.567 9.343z'
        fill='#fff'
      />
    </svg>
  );
}
