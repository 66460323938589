import { METHOD } from 'constants/method';

export default async function requestAddDomain(domainAddress) {
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const { id: memberNo } = authentication;

  const res = await fetch('/_domain/add', {
    method: METHOD.POST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      memberNo,
    },
    body: new URLSearchParams({
      memberNo,
      domainAddress,
      useSixshopNs: 1,
    }),
  });

  return res.json();
}
