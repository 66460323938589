import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { color, COLOR } from 'styles/colors';
import { PATH } from 'constants/path';
import { PRICE, ALERT_MESSAGE } from 'constants/domain';
import { RESULT_MESSAGE as VALIDATION_RESULT_MESSAGE } from 'constants/validation';
import useScript from 'hooks/useScript';
import requestDomainPaymentData from 'api/domain/requestDomainPaymentData';
import IcoPlusCircleBig from 'icons/IcoPlusCircleBig';
import IcoEqualsCircleBig from 'icons/IcoEqualsCircleBig';
import validateInfo from 'utils/domain/validateInfo';
import verifyCodeInput from 'utils/domain/verifyCodeInput';
import Checkbox from 'components/atoms/Checkbox';
import Radio from 'components/atoms/Radio';
import Alert from 'components/atoms/Alert';
import HeadlineHeader from 'components/molecules/HeadlineHeader';
import Select from 'components/molecules/Select';
import Table from 'components/molecules/Table';
import Section from 'components/molecules/Section';
import DomainRegistrationForm from 'components/organisms/DomainRegistrationForm';
import TossPayments from 'components/organisms/TossPayments';
import BodyWrapperWithSectionDividers from 'components/wrappers/BodyWrapperWithSectionDividers';
import ColorWrapper from 'components/wrappers/ColorWrapper';
import FlexBox from 'components/wrappers/FlexBox';
import DetailPageLayout from 'layouts/DetailPageLayout';
import { useNewDomainStore } from 'stores/domain';

const FormWrapper = styled.div`
  max-width: 720px;
`;

const RadioWrapper = styled.div`
  display: inline-grid;
  grid-gap: 40px;
  grid-template-columns: auto auto;
  height: 24px;
  margin-bottom: 30px;
`;

const AgreementsWrapper = styled(FlexBox)`
  margin-bottom: 30px;
`;

const PriceSummaryWrapper = styled(FlexBox)`
  justify-content: flex-end;
  padding: 15px 0;
`;

const PriceSummaryElement = styled(FlexBox)`
  font-size: 18px;
`;

const PriceSummaryDescription = styled(ColorWrapper)`
  margin-right: 6px;
`;

const PriceSummaryIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin: 0 15px;
`;

const FinalPrice = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
`;

const TableRow = styled(FlexBox)`
  padding: 10px 0;
  font-size: 15px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: ${({ flex }) => flex};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export default function DomainRegistration() {
  const domainInput = useNewDomainStore((store) => store.selectedDomain);
  const period = useNewDomainStore((store) => store.period);
  const setPeriod = useNewDomainStore((store) => store.setPeriod);
  const history = useHistory();
  const { origin } = window.location;
  const [domain, setDomain] = useState(domainInput);

  useEffect(() => {
    if (domain) {
      sessionStorage.setItem('domain_temp', domain);

      return;
    }

    const storedDomain = sessionStorage.getItem('domain_temp');

    if (storedDomain) {
      setDomain(storedDomain);
    } else {
      history.push(`${PATH.STORE_SETTING}${PATH.DOMAIN}`);
    }
  }, []);

  const ctfyCodes = {
    사업자등록증: 'BUSINESS_REGISTRATION_CERTIFICATE',
    '개인 사업자등록증': 'INDIVIDUAL_BUSINESS_REGISTRATION_CERTIFICATE',
    법인등록증: 'CORPORATE_REGISTRATION_CERTIFICATE',
    고유번호증: 'IDENTITY_NUMBER_CERTIFICATE',
    학교인가증: 'EDUCATION_CERTIFICATE',
    기타: 'ETC',
  };
  const [alertMessage, setAlertMessage] = useState('');
  const [isOwnerTypeIndividual, setIsOwnerTypeIndividual] = useState(true);
  const [ownerKoreanName, setOwnerKoreanName] = useState('');
  const [ownerEnglishName, setOwnerEnglishName] = useState('');
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState({
    head: '',
    middle: '',
    tail: '',
  });
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ownerVerificationCode, setOwnerVerificationCode] = useState('');
  const [ownerVerificationCodeInput, setOwnerVerificationCodeInput] = useState('');
  const [isOwnerEmailVerified, setIsOwnerEmailVerified] = useState(false);
  const [ownerAddress, setOwnerAddress] = useState({
    zipCode: '',
    address: '',
    addressEnglish: '',
    roadAddress: '',
    roadAddressEnglish: '',
    bname: '',
    bnameEnglish: '',
    jibunAddress: '',
    jibunAddressEnglish: '',
    additionalAddress: '',
  });
  const [isOwnerManagerSame, setIsOwnerManagerSame] = useState(true);
  const [managerKoreanName, setManagerKoreanName] = useState('');
  const [managerEnglishName, setManagerEnglishName] = useState('');
  const [managerPhoneNumber, setManagerPhoneNumber] = useState({
    head: '',
    middle: '',
    tail: '',
  });
  const [managerEmail, setManagerEmail] = useState('');
  const [managerVerificationCode, setManagerVerificationCode] = useState('');
  const [managerVerificationCodeInput, setManagerVerificationCodeInput] = useState('');
  const [isManagerEmailVerified, setIsManagerEmailVerified] = useState(false);
  const [managerAddress, setManagerAddress] = useState({
    zipCode: '',
    address: '',
    addressEnglish: '',
    roadAddress: '',
    roadAddressEnglish: '',
    bname: '',
    bnameEnglish: '',
    jibunAddress: '',
    jibunAddressEnglish: '',
    additionalAddress: '',
  });
  const [registrationCertificateType, setRegistrationCertificateType] = useState('사업자등록증');
  const [registrationCertificate, setRegistrationCertificate] = useState('');
  const [institutionKoreanName, setInstitutionKoreanName] = useState('');
  const [institutionEnglishName, setInstitutionEnglishName] = useState('');
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);

  useScript('//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');

  function handleOwnerTypeChange() {
    setIsOwnerTypeIndividual(!isOwnerTypeIndividual);
    initializeOwnerInfo();
  }

  function handleIsOwnerMangerSameChange() {
    setIsOwnerManagerSame(!isOwnerManagerSame);
    initializeManagerInfo();
  }

  function initializeOwnerInfo() {
    setOwnerKoreanName('');
    setOwnerEnglishName('');
    setOwnerPhoneNumber({
      head: '',
      middle: '',
      tail: '',
    });
    setOwnerEmail('');
    setOwnerVerificationCode('');
    setOwnerVerificationCodeInput('');
    setIsOwnerEmailVerified(false);
    setOwnerAddress({
      zipCode: '',
      address: '',
      addressEnglish: '',
      roadAddress: '',
      roadAddressEnglish: '',
      bname: '',
      bnameEnglish: '',
      jibunAddress: '',
      jibunAddressEnglish: '',
      additionalAddress: '',
    });
    setRegistrationCertificateType('사업자등록증');
    setRegistrationCertificate('');
    setInstitutionKoreanName('');
    setInstitutionEnglishName('');
  }

  function initializeManagerInfo() {
    setManagerKoreanName('');
    setManagerEnglishName('');
    setManagerPhoneNumber({
      head: '',
      middle: '',
      tail: '',
    });
    setManagerEmail('');
    setManagerVerificationCode('');
    setManagerVerificationCodeInput('');
    setIsManagerEmailVerified(false);
    setManagerAddress({
      zipCode: '',
      address: '',
      addressEnglish: '',
      roadAddress: '',
      roadAddressEnglish: '',
      bname: '',
      bnameEnglish: '',
      jibunAddress: '',
      jibunAddressEnglish: '',
      additionalAddress: '',
    });
  }

  function validateOwnerInfo() {
    return validateInfo(
      true,
      setAlertMessage,
      ownerKoreanName,
      ownerEnglishName,
      ownerPhoneNumber,
      isOwnerEmailVerified,
      ownerVerificationCode,
      ownerAddress,
      isOwnerTypeIndividual,
      registrationCertificateType,
      registrationCertificate,
      institutionKoreanName,
      institutionEnglishName
    );
  }

  function validateManagerInfo() {
    return validateInfo(
      false,
      setAlertMessage,
      managerKoreanName,
      managerEnglishName,
      managerPhoneNumber,
      isManagerEmailVerified,
      managerVerificationCode,
      managerAddress,
      isOwnerTypeIndividual
    );
  }

  function verifyEmailCodeInput(isOwnersForm, verificationCode, input) {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);

    const inputVerificationResult = verifyCodeInput(verificationCode, input);

    if (
      !inputVerificationResult.ok &&
      inputVerificationResult.message === VALIDATION_RESULT_MESSAGE.NO_CODE
    ) {
      isOwnersForm
        ? setAlertMessage(ALERT_MESSAGE.NO_OWNER_EMAIL_CODE)
        : setAlertMessage(ALERT_MESSAGE.NO_MANAGER_EMAIL_CODE);

      return;
    }

    if (
      !inputVerificationResult.ok &&
      inputVerificationResult.message === VALIDATION_RESULT_MESSAGE.WRONG_INPUT
    ) {
      isOwnersForm
        ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_VERIFICATION_CODE_INPUT)
        : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_VERIFICATION_CODE_INPUT);

      return;
    }

    isOwnersForm ? setIsOwnerEmailVerified(true) : setIsManagerEmailVerified(true);
  }

  function closeAlert() {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);
  }

  function handleCheckboxChange() {
    setIsAgreementsChecked(!isAgreementsChecked);
  }

  function validateForm() {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);

    if (!validateOwnerInfo() || (!isOwnerManagerSame && !validateManagerInfo())) {
      return;
    }

    if (!isAgreementsChecked) {
      setAlertMessage(ALERT_MESSAGE.CHECK_AGREEMENTS);

      return;
    }

    return true;
  }

  function requestPaymentData() {
    return requestDomainPaymentData({
      item: {
        address: domain,
        period,
        listPrice: PRICE,
      },
      owner: {
        type: isOwnerTypeIndividual ? 'INDIVIDUAL' : ctfyCodes[registrationCertificateType],
        certificateNumber: !isOwnerTypeIndividual ? registrationCertificate : null,
        organizationName: !isOwnerTypeIndividual ? institutionKoreanName : null,
        engOrganizationName: !isOwnerTypeIndividual ? institutionEnglishName : null,
        name: ownerKoreanName,
        engName: ownerEnglishName,
        zipcode: ownerAddress.zipCode,
        addr1: ownerAddress.roadAddress,
        addr2: ownerAddress.additionalAddress,
        engAddr1: ownerAddress.roadAddressEnglish,
        city: 'Korea',
        country: 'KR',
        phone: `${ownerPhoneNumber.head}${ownerPhoneNumber.middle}${ownerPhoneNumber.tail}`,
        email: ownerEmail,
      },
      manager: {
        name: isOwnerManagerSame ? ownerKoreanName : managerKoreanName,
        engName: isOwnerManagerSame ? ownerEnglishName : managerEnglishName,
        zipcode: isOwnerManagerSame ? ownerAddress.zipCode : managerAddress.zipCode,
        addr1: isOwnerManagerSame ? ownerAddress.roadAddress : managerAddress.roadAddress,
        addr2: isOwnerManagerSame
          ? ownerAddress.additionalAddress
          : managerAddress.additionalAddress,
        engAddr1: isOwnerManagerSame
          ? ownerAddress.roadAddressEnglish
          : managerAddress.roadAddressEnglish,
        city: 'Korea',
        country: 'KR',
        phone: isOwnerManagerSame
          ? `${ownerPhoneNumber.head}${ownerPhoneNumber.middle}${ownerPhoneNumber.tail}`
          : `${managerPhoneNumber.head}${managerPhoneNumber.middle}${managerPhoneNumber.tail}`,
        email: isOwnerManagerSame ? ownerEmail : managerEmail,
      },
    });
  }

  return (
    <DetailPageLayout>
      {alertMessage && (
        <Alert
          text={alertMessage}
          top='0'
          fontSize='16px'
          padding='15px'
          closeFunction={closeAlert}
        />
      )}
      <HeadlineHeader title='구매 신청하기'>
        도메인 구매 후 웹사이트 접속까지 최대 48시간 정도 소요될 수 있습니다.
      </HeadlineHeader>
      <BodyWrapperWithSectionDividers>
        <Section title='신청 정보'>
          <Table
            hasHeader
            columnsInfo={[
              { title: '도메인 주소', flex: 3, alignment: 'start' },
              { title: '이용기간', flex: 1, alignment: 'center' },
              { title: '가격', flex: 1, alignment: 'center' },
            ]}
          >
            <TableRow>
              <FlexWrapper flex={3}>{domain}</FlexWrapper>
              <FlexWrapper flex={1} justifyContent='center'>
                <Select
                  width='120px'
                  selected={`${period}년`}
                  options={['1년', '2년', '3년', '4년', '5년']}
                  setFunction={(newSelected) => {
                    setPeriod(parseInt(newSelected, 10));
                  }}
                />
              </FlexWrapper>
              <FlexWrapper flex={1} justifyContent='center'>
                {`${PRICE.toLocaleString()}원`}
              </FlexWrapper>
            </TableRow>
          </Table>
          <PriceSummaryWrapper>
            <PriceSummaryElement>
              <PriceSummaryDescription color={color[COLOR.GRAY][500]}>
                도메인 가격
              </PriceSummaryDescription>
              {`${PRICE.toLocaleString()}원`}
            </PriceSummaryElement>
            <PriceSummaryIconWrapper>
              <IcoPlusCircleBig color={color[COLOR.GRAY][500]} rotation={45} />
            </PriceSummaryIconWrapper>
            <PriceSummaryElement>
              <PriceSummaryDescription color={color[COLOR.GRAY][500]}>
                이용기간
              </PriceSummaryDescription>
              {period}년
              <PriceSummaryIconWrapper>
                <IcoEqualsCircleBig />
              </PriceSummaryIconWrapper>
            </PriceSummaryElement>
            <FinalPrice>{(PRICE * period).toLocaleString()}원</FinalPrice>
          </PriceSummaryWrapper>
        </Section>
        <Section title='소유자 정보'>
          <RadioWrapper>
            <Radio
              label='개인'
              name='ownerType'
              value='individual'
              isChecked={isOwnerTypeIndividual}
              onChange={handleOwnerTypeChange}
            />
            <Radio
              label='기관'
              name='ownerType'
              value='institution'
              isChecked={!isOwnerTypeIndividual}
              onChange={handleOwnerTypeChange}
            />
          </RadioWrapper>
          <FormWrapper>
            <DomainRegistrationForm
              setAlert={setAlertMessage}
              individualOrInstitution={isOwnerTypeIndividual ? 'individual' : 'institution'}
              ownerOrManager='owner'
              koreanName={ownerKoreanName}
              setKoreanName={setOwnerKoreanName}
              englishName={ownerEnglishName}
              setEnglishName={setOwnerEnglishName}
              phoneNumber={ownerPhoneNumber}
              setPhoneNumber={setOwnerPhoneNumber}
              email={ownerEmail}
              setEmail={setOwnerEmail}
              verificationCode={ownerVerificationCode}
              setVerificationCode={setOwnerVerificationCode}
              verificationCodeInput={ownerVerificationCodeInput}
              setVerificationCodeInput={setOwnerVerificationCodeInput}
              isEmailVerified={isOwnerEmailVerified}
              setIsEmailVerified={setIsOwnerEmailVerified}
              address={ownerAddress}
              setAddress={setOwnerAddress}
              registrationCertificateType={registrationCertificateType}
              setRegistrationCertificateType={setRegistrationCertificateType}
              registrationCertificate={registrationCertificate}
              setRegistrationCertificate={setRegistrationCertificate}
              institutionKoreanName={institutionKoreanName}
              setInstitutionKoreanName={setInstitutionKoreanName}
              institutionEnglishName={institutionEnglishName}
              setInstitutionEnglishName={setInstitutionEnglishName}
              verifyCodeInput={() =>
                verifyEmailCodeInput(true, ownerVerificationCode, ownerVerificationCodeInput)
              }
            />
          </FormWrapper>
        </Section>
        <Section title='관리자 정보'>
          <RadioWrapper>
            <Radio
              label='소유자 정보와 같음'
              name='isOwnerManagerSame'
              isChecked={isOwnerManagerSame}
              onChange={handleIsOwnerMangerSameChange}
            />
            <Radio
              label='신규 입력'
              name='isOwnerManagerSame'
              isChecked={!isOwnerManagerSame}
              onChange={handleIsOwnerMangerSameChange}
            />
          </RadioWrapper>
          {!isOwnerManagerSame && (
            <FormWrapper>
              <DomainRegistrationForm
                setAlert={setAlertMessage}
                individualOrInstitution='individual'
                ownerOrManager='manager'
                koreanName={managerKoreanName}
                setKoreanName={setManagerKoreanName}
                englishName={managerEnglishName}
                setEnglishName={setManagerEnglishName}
                phoneNumber={managerPhoneNumber}
                setPhoneNumber={setManagerPhoneNumber}
                email={managerEmail}
                setEmail={setManagerEmail}
                verificationCode={managerVerificationCode}
                setVerificationCode={setManagerVerificationCode}
                verificationCodeInput={managerVerificationCodeInput}
                setVerificationCodeInput={setManagerVerificationCodeInput}
                isEmailVerified={isManagerEmailVerified}
                setIsEmailVerified={setIsManagerEmailVerified}
                address={managerAddress}
                setAddress={setManagerAddress}
                registrationCertificateType={registrationCertificateType}
                setRegistrationCertificateType={setRegistrationCertificateType}
                registrationCertificate={registrationCertificate}
                setRegistrationCertificate={setRegistrationCertificate}
                institutionKoreanName={institutionKoreanName}
                setInstitutionKoreanName={setInstitutionKoreanName}
                institutionEnglishName={institutionEnglishName}
                setInstitutionEnglishName={setInstitutionEnglishName}
                verifyCodeInput={() =>
                  verifyEmailCodeInput(false, managerVerificationCode, managerVerificationCodeInput)
                }
              />
            </FormWrapper>
          )}
        </Section>
        <Section>
          <AgreementsWrapper>
            <Checkbox isChecked={isAgreementsChecked} onChange={handleCheckboxChange}>
              [필수] 도메인&nbsp;
              <a href='/domain-policy' target='_blank' rel='noreferrer'>
                이용약관
              </a>
              에 동의합니다.
            </Checkbox>
          </AgreementsWrapper>
          {domain && (
            <TossPayments
              preRequest={validateForm}
              fetchPaymentData={requestPaymentData}
              successUrl={`${origin}/store-setting/domain/new/purchase-result/success`}
              failUrl={`${origin}/store-setting/domain/new/purchase-result/fail`}
            />
          )}
        </Section>
      </BodyWrapperWithSectionDividers>
    </DetailPageLayout>
  );
}
