import { METHOD } from 'constants/method';
import { API_PATH } from 'constants/path';

export default async function requestRemoveDomain(domainName) {
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const { id: memberNo } = authentication;

  const res = await fetch(
    `${process.env.REACT_APP_SIXSHOP2_USERHOME_SERVER_HOST}${API_PATH.DOMAINS}${API_PATH.DELETE}`,
    {
      method: METHOD.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        memberNo,
        domainName,
      }),
    }
  );

  return res.json();
}
