import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 240px;
  min-width: 240px;
  height: 100vh;
  background-color: white;
  border-right: 1px solid ${color[COLOR.GRAY][200]};

  a {
    text-decoration: none;
  }
`;

export default Wrapper;
