import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';
import IcoCloseLine from 'icons/IcoCloseLine';
import IcoWrongCircle from 'icons/IcoWrongCircle';
import IcoCheckCircle from 'icons/IcoCheckCircle';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import FlexBox from 'components/wrappers/FlexBox';

const highlight = keyframes`
  from { background-color: #FFFF99; }
`;

const StyledAlert = styled(FlexBox)`
  top: ${({ top }) => top};
  z-index: 9999;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1180px;
  justify-content: space-between;
  opacity: 1;
  padding: ${({ padding }) => padding};
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
  border-radius: 4px;
  color: ${({ type }) => (type === 'error' ? color[COLOR.RED][500] : color[COLOR.GREEN][500])};
  background-color: ${({ type }) => (type === 'error' ? color[COLOR.RED][100] : '#EBF8F6')};
  animation: ${css`
    ${highlight} 600ms;
  `};
`;

const Content = styled.span`
  display: flex;
  align-items: center;

  .item {
    display: flex;
    margin-right: 10px;
    align-items: center;
  }
`;

export default function Alert(props) {
  const { type, text, top, padding, fontSize, closeFunction } = props;
  const isTypeError = type === 'error';

  return (
    <StyledAlert type={type} top={top} padding={padding} $fontSize={fontSize}>
      <Content>
        <span className='item'>{isTypeError ? <IcoWrongCircle /> : <IcoCheckCircle />}</span>
        {text}
      </Content>
      <PointerOnHoverWrapper onClick={closeFunction}>
        <IcoCloseLine
          width={15}
          height={15}
          color={isTypeError ? color[COLOR.RED][500] : color[COLOR.GREEN][500]}
        />
      </PointerOnHoverWrapper>
    </StyledAlert>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'success']),
  text: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
  closeFunction: PropTypes.func.isRequired,
};

Alert.defaultProps = {
  type: 'error',
  padding: '0',
  fontSize: '14px',
};
