import { RESULT_MESSAGE } from 'constants/validation';

export default function verifyCodeInput(verificationCode, verificationCodeInput) {
  if (!verificationCode) {
    return {
      ok: false,
      message: RESULT_MESSAGE.NO_CODE,
    };
  }

  if (verificationCode !== verificationCodeInput) {
    return {
      ok: false,
      message: RESULT_MESSAGE.WRONG_INPUT,
    };
  }

  return { ok: true };
}
