import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlexBox from 'components/wrappers/FlexBox';

const Wrapper = styled.section`
  padding: 30px 0;
`;

const TitleWrapper = styled(FlexBox)`
  height: 32px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
`;

export default function Section(props) {
  const { title, children } = props;

  return (
    <Wrapper>
      {title && <TitleWrapper>{title}</TitleWrapper>}
      {children}
    </Wrapper>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Section.defaultProps = {
  title: '',
  children: null,
};
