import { useQuery } from 'react-query';

import requestMemberInfo from 'api/member/requestMemberInfo';
import useMemberNo from 'hooks/useMemberNo';

export default function useMemberInfo() {
  const memberNo = useMemberNo();

  const { data: memberInfoResponse } = useQuery('member', () => requestMemberInfo(memberNo), {
    enabled: !!memberNo,
    refetchOnWindowFocus: false,
  });

  return memberInfoResponse;
}
