import PropTypes from 'prop-types';

export default function IcoCheckLine(props) {
  const { color } = props;

  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.476 2.42a.75.75 0 01.104 1.056l-7.385 9a.75.75 0 01-1.07.09l-4.616-4a.75.75 0 01.982-1.133l4.033 3.496 6.896-8.405a.75.75 0 011.056-.104z'
        fill={color}
      />
    </svg>
  );
}

IcoCheckLine.propTypes = {
  color: PropTypes.string.isRequired,
};
