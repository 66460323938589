import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';
import useOuterClick from 'hooks/useOuterClick';
import useAnimation from 'hooks/useAnimation';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    transform: translateY(25px);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from  {
    transform: translateY(25px);
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Container = styled.div`
  position: absolute;
  top: calc(100% - 23px);
  right: 0;
  width: 200px;
  z-index: 1;
  border: 1px solid ${color[COLOR.GRAY][350]};
  border-radius: 2px;
  background-color: ${color[COLOR.WHITE]};
  animation: ${css`
    ${({ isActive }) => (isActive ? fadeIn : fadeOut)} 200ms linear forwards;
  `};

  button {
    text-align: left;
    line-height: 19px;
    position: relative;
    display: list-item;
    width: 100%;
    padding: 13px 20px;
    margin: -1px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }

  button:hover {
    color: ${color[COLOR.WHITE]};
    background-color: #28a3d9;
  }
`;

export default function Dropdown(props) {
  const { isActive, onOuterClick, children } = props;

  const selfRef = useOuterClick(onOuterClick);
  const [shouldRender, unmount] = useAnimation(isActive);

  return (
    shouldRender && (
      <Container ref={selfRef} isActive={isActive} onAnimationEnd={unmount}>
        {children}
      </Container>
    )
  );
}

Dropdown.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onOuterClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Dropdown.defaultProps = {
  onOuterClick: () => {},
};
