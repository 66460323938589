import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.i`
  svg {
    transform: ${({ $transform }) => $transform};
  }
`;

const rotate = (direction) => {
  switch (direction) {
    case 'left':
      return 'rotate(90deg)';
    case 'up':
      return 'rotate(180deg)';
    case 'right':
      return 'rotate(-90deg)';
    default:
      return 'rotate(0deg)';
  }
};

export default function IcoChevronDownLine(props) {
  const { width, height, color, direction } = props;

  return (
    <Wrapper $transform={rotate(direction)}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2 4l4 4 4-4'
          stroke={color}
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Wrapper>
  );
}

IcoChevronDownLine.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string.isRequired,
  direction: PropTypes.string,
};

IcoChevronDownLine.defaultProps = {
  width: 12,
  height: 12,
  direction: 'down',
};
