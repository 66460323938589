import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import ChevronRight from 'icons/ChevronRight';

const Stopover = styled.span`
  display: flex;
  align-items: center;
`;

const ChevronWrapper = styled.span`
  margin: 0 5px;
`;

const Destination = styled.span`
  color: ${color[COLOR.BLACK]};
`;

export default function SectionPath(props) {
  const { sectionPath } = props;

  return (
    <>
      {sectionPath.map((section, index) =>
        index === sectionPath.length - 1 ? (
          <Destination key={section}>{section}</Destination>
        ) : (
          <Stopover key={section}>
            {section}
            <ChevronWrapper>
              <ChevronRight width={10} height={10} color={color[COLOR.GRAY][400]} rotation={90} />
            </ChevronWrapper>
          </Stopover>
        )
      )}
    </>
  );
}

SectionPath.propTypes = {
  sectionPath: PropTypes.arrayOf(PropTypes.string).isRequired,
};
