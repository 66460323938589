import { decodeJwt } from 'utils/auth/jwt';

export default function checkIsTokenExpired() {
  if (process.env.NODE_ENV === 'development') return false;

  const authentication = JSON.parse(localStorage.getItem('authentication'));

  if (!authentication) return true;

  const { access_token: token } = authentication;
  const { exp } = decodeJwt(token);

  return new Date(exp * 1000) < new Date();
}
