import PropTypes from 'prop-types';

export default function IcoCheckboxOn(props) {
  const { className } = props;

  return (
    <svg
      className={className}
      width={20}
      height={20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x={1} y={1} width={18} height={18} rx={3} fill='#6D6BD1' />
      <path
        d='M5.5 10l3.462 3L14.5 6.25'
        stroke='#fff'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

IcoCheckboxOn.propTypes = {
  className: PropTypes.string,
};

IcoCheckboxOn.defaultProps = {
  className: '',
};
