import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { color, COLOR } from 'styles/colors';
import IcoArrowPrevLineS from 'icons/IcoArrowPrevLineS';
import EntireContentWrapper from 'components/wrappers/EntireContentWrapper';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import FlexBox from 'components/wrappers/FlexBox';

const BackWrapper = styled(PointerOnHoverWrapper)`
  &:hover {
    color: ${color[COLOR.GRAY][400]};
  }
`;

const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: ${color[COLOR.WHITE]};
  border-bottom: 1px solid ${color[COLOR.GRAY][200]};
`;

const Header = styled(FlexBox)`
  justify-content: space-between;
  width: 1180px;
  height: 60px;
  margin: 0 auto;
  padding: 0 40px;
  color: ${color[COLOR.GRAY][500]};
  font-weight: bold;
  font-size: 14px;
`;

export default function DetailPageLayout(props) {
  const { title, onBackClick, children } = props;
  const [isBackButtonHovered, setIsBackButtonHovered] = useState(false);
  const history = useHistory();

  function handleBackClick() {
    if (onBackClick) {
      onBackClick();

      return;
    }

    history.goBack();
  }

  function handleBackButtonMouseOver() {
    setIsBackButtonHovered(true);
  }

  function handlerBackButtonMouseOut() {
    setIsBackButtonHovered(false);
  }

  return (
    <>
      <HeaderWrapper>
        <Header>
          <BackWrapper
            onClick={handleBackClick}
            onMouseOver={handleBackButtonMouseOver}
            onMouseOut={handlerBackButtonMouseOut}
          >
            <IcoArrowPrevLineS
              color={isBackButtonHovered ? color[COLOR.GRAY][400] : color[COLOR.GRAY][500]}
            />
            &nbsp;뒤로 돌아가기
          </BackWrapper>
          {title}
        </Header>
      </HeaderWrapper>
      <EntireContentWrapper>{children}</EntireContentWrapper>
    </>
  );
}

DetailPageLayout.propTypes = {
  title: PropTypes.string,
  onBackClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

DetailPageLayout.defaultProps = {
  title: '',
  onBackClick: null,
};
