export default async function requestFinishDomainRegistration(type, orderId) {
  const res = await fetch(
    `/apis/v2/domains/orders/${type === 'extend' ? 'extension/' : ''}${orderId}/done`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }
  );

  if (res.status >= 400) {
    throw new Error(res.statusText);
  }

  return res.json();
}
