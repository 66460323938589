export const PATH = {
  HELP: 'https://help.sixshop.com',
  LEARN_SIXSHOP: '/learn-sixshop',
  GET_STARTED: '/get-started',
  PREPARE_TO_SELL: '/prepare-to-sell',
  STORE_SETTING: '/store-setting',
  DOMAIN: '/domain',
  DOMAINS: '/domains',
  NEW: '/new',
  SEARCH: '/search',
  HOME: '/home',
};

export const API_PATH = {
  MEMBERS: '/members',
  DOMAINS: '/domains',
  SIXSHOP2_DOMAIN: '/_domain',
  CHECK: '/check',
  SET_MAIN_DOMAIN: '/setMainDomain',
  DELETE: '/delete',
};
