import PropTypes from 'prop-types';

export default function Modal(props) {
  const { children } = props;

  return <div>{children}</div>;
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};
