import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import FlexBox from 'components/wrappers/FlexBox';

export const ThumbnailWrapper = styled(FlexBox)`
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${color[COLOR.GRAY][200]};
  border-radius: 8px;
`;

export const ThumbnailPlaceholder = styled.div`
  color: ${color[COLOR.GRAY][500]};
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
`;
