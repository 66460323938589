import { useEffect } from 'react';

export default function useScript(url, body) {
  useEffect(() => {
    const script = document.createElement('script');

    if (url) {
      script.src = url;
      script.defer = true;
    }

    if (body) {
      script.innerHTML = body;
    }

    document.head.appendChild(script);

    return () => document.head.removeChild(script);
  }, [url]);
}
