export default function IcoMasterPurple(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.785 5.38L3.9 10.746a.4.4 0 00.372.253h7.456a.4.4 0 00.372-.253l2.115-5.368c.194-.492-.409-.909-.8-.553L11 7.014 8.423 2.933a.5.5 0 00-.846 0L5 7.013 2.586 4.827c-.392-.356-.995.061-.801.553zM4 12.25a.75.75 0 000 1.5h8a.75.75 0 100-1.5H4z'
        fill='#6D6BD1'
      />
    </svg>
  );
}
