import PropTypes from 'prop-types';

export default function IcoPlusCircleBig(props) {
  const { color, rotation } = props;

  return (
    <svg
      width={20}
      height={20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      transform={`rotate(${rotation})`}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 19a9 9 0 100-18 9 9 0 000 18zm0-13.211a.75.75 0 01.75.75V9.25h2.71a.75.75 0 010 1.5h-2.71v2.712a.75.75 0 01-1.5 0V10.75H6.537a.75.75 0 010-1.5H9.25V6.539a.75.75 0 01.75-.75z'
        fill={color}
      />
    </svg>
  );
}

IcoPlusCircleBig.propTypes = {
  color: PropTypes.string.isRequired,
  rotation: PropTypes.number,
};

IcoPlusCircleBig.defaultProps = {
  rotation: 0,
};
