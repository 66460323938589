import PropTypes from 'prop-types';

import { color as colors, COLOR } from 'styles/colors';

export default function IcoCheckCircle(props) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 1a7 7 0 100 14A7 7 0 008 1zm3.603 4.946a.75.75 0 10-1.206-.892L7.212 9.367 5.52 7.737a.75.75 0 10-1.04 1.081l2.307 2.222a.75.75 0 001.124-.095l3.692-5z'
        fill={color}
      />
    </svg>
  );
}

IcoCheckCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

IcoCheckCircle.defaultProps = {
  width: 16,
  height: 16,
  color: colors[COLOR.GREEN][500],
};
