export default function IcoWrongGray() {
  return (
    <svg width={48} height={48} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width={48} height={48} rx={4} fill='#F0F0F5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 14a2 2 0 00-2 2v10a2 2 0 104 0V16a2 2 0 00-2-2zm0 16a2 2 0 100 4 2 2 0 000-4z'
        fill='#B9B9C5'
      />
    </svg>
  );
}
