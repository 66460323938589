import PropTypes from 'prop-types';

import { color as colors } from 'styles/colors';

export default function IcoMenuLine(props) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 3C1 2.44772 1.44772 2 2 2H18C18.5523 2 19 2.44772 19 3C19 3.55228 18.5523 4 18 4H2C1.44772 4 1 3.55228 1 3ZM1 10C1 9.44771 1.44772 9 2 9H18C18.5523 9 19 9.44771 19 10C19 10.5523 18.5523 11 18 11H2C1.44772 11 1 10.5523 1 10ZM2 16C1.44772 16 1 16.4477 1 17C1 17.5523 1.44772 18 2 18H18C18.5523 18 19 17.5523 19 17C19 16.4477 18.5523 16 18 16H2Z'
        fill={color}
      />
    </svg>
  );
}

IcoMenuLine.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

IcoMenuLine.defaultProps = {
  width: 16,
  height: 16,
  color: colors.gray[400],
};
