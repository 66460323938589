import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';

const Textarea = styled.textarea`
  min-height: 50px;
  padding: 15px 14px 14px;
  font-size: 14px;
  line-height: 19px;
  border: ${({ border }) => border || `1px solid ${color[COLOR.GRAY][300]}`};
  border-radius: 10px;
  resize: ${({ resize }) => resize || 'none'};
`;

export default Textarea;
