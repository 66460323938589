import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { color, COLOR } from 'styles/colors';
import IcoTriangle from 'icons/IcoTriangle';
import { PLAN } from 'constants/plan';
import useMemberNo from 'hooks/useMemberNo';
import requestConnectedSiteSettingByMemberNo from 'api/connectedSite/requestConnectedSiteSettingByMemberNo';
import requestConnectedSitesInfo from 'api/connectedSite/requestConnectedSitesInfo';
import { ThumbnailWrapper, ThumbnailPlaceholder } from 'components/wrappers/MemberInfoThumbnail';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import MemberInfoModal from 'components/organisms/MemberInfoModal';
import useMemberInfo from 'hooks/useMemberInfo';

const Wrapper = styled(PointerOnHoverWrapper)`
  width: 240px;
  padding: 10px 14px;
  background-color: ${color[COLOR.WHITE]};
  border-right: 1px solid ${color[COLOR.GRAY][200]};
`;

const InfoWrapper = styled.div`
  flex: 1;
  margin-left: 6px;
`;

const MemberNameWrapper = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

const MemberPlanWrapper = styled.span`
  display: block;
  color: ${color[COLOR.GRAY][500]};
  font-size: 10px;
  line-height: 15px;
`;

export default function MemberInfo() {
  const [isMemberInfoModalOpen, setIsMemberInfoModalOpen] = useState(false);
  const memberNo = useMemberNo();
  const memberInfo = useMemberInfo();
  const { data: connectedSitesInfo } = useQuery(
    'connectedSitesInfo',
    () => requestConnectedSitesInfo(memberNo),
    {
      enabled: !!memberNo,
    }
  );

  // 개발환경에서 SESSION 쿠키를 주입합니다.
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') return;

    requestConnectedSiteSettingByMemberNo(memberNo);
  }, [memberNo]);

  function toggleMemberInfoModal() {
    setIsMemberInfoModalOpen(!isMemberInfoModalOpen);
  }

  function handleMemberInfoModalOuterClick() {
    if (!isMemberInfoModalOpen) return;

    toggleMemberInfoModal();
  }

  return (
    <>
      <Wrapper onClick={toggleMemberInfoModal}>
        {memberInfo?.favicon ? (
          <ThumbnailWrapper
            style={{
              backgroundImage: `url(${process.env.REACT_APP_CONTENTS_STORAGE}${memberInfo.favicon})`,
              backgroundSize: 'cover',
            }}
          />
        ) : (
          <ThumbnailWrapper>
            <ThumbnailPlaceholder>{memberInfo?.userName[0]}</ThumbnailPlaceholder>
          </ThumbnailWrapper>
        )}
        <InfoWrapper>
          <MemberNameWrapper>{memberInfo?.userName}</MemberNameWrapper>
          <MemberPlanWrapper>{PLAN[memberInfo?.plan]}</MemberPlanWrapper>
        </InfoWrapper>
        <IcoTriangle
          width={10}
          height={10}
          color={color[COLOR.GRAY][600]}
          direction={isMemberInfoModalOpen ? 'up' : 'down'}
        />
      </Wrapper>
      <MemberInfoModal
        isOpen={isMemberInfoModalOpen}
        connectedSites={connectedSitesInfo?.siteConnections}
        memberInfo={memberInfo}
        onOuterClick={handleMemberInfoModalOuterClick}
      />
    </>
  );
}
