import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';

const BodyWrapperWithSectionDividers = styled.div`
  & > :nth-child(n) {
    border-top: 1px solid ${color[COLOR.GRAY][200]};
  }
`;

export default BodyWrapperWithSectionDividers;
