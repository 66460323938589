import { useRef, useEffect } from 'react';

export default function useOuterClick(callback) {
  const callbackRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);

    function handleClick(event) {
      if (!innerRef.current || !callbackRef.current || innerRef.current.contains(event.target)) {
        return;
      }

      callbackRef.current(event);
    }
  }, []);

  return innerRef;
}
