import { METHOD } from 'constants/method';

export default async function requestDeleteDnsRecord(domainAddress, dnsType, dnsName) {
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const { id: memberNo } = authentication;

  const res = await fetch('/_domain/deleteDns', {
    method: METHOD.POST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      memberNo,
    },
    body: new URLSearchParams({
      memberNo,
      domainAddress,
      dnsType,
      dnsName,
    }),
  });

  return res.json();
}
