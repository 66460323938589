import PropTypes from 'prop-types';

export default function IcoArrowPrevLineS(props) {
  const { color } = props;

  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.286 7.64a.75.75 0 000 1.06l4.342 4.342a.75.75 0 101.06-1.06L3.627 8.92h10.557a.75.75 0 000-1.5H3.627l3.061-3.062a.75.75 0 10-1.06-1.06L1.286 7.64z'
        fill={color}
      />
    </svg>
  );
}

IcoArrowPrevLineS.propTypes = {
  color: PropTypes.string.isRequired,
};
