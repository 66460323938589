import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import emailValidator from 'email-validator';

import { color, COLOR } from 'styles/colors';
import requestVerificationCode from 'api/domain/requestVerificationCode';
import removeSpaces from 'utils/domain/removeSpaces';
import Input from 'components/atoms/Input';
import { GrayButton, PurpleButton, WhiteButton } from 'components/atoms/Button';
import InputWithTitleOnSide, { Title } from 'components/molecules/InputWithTitleOnSide';
import Select from 'components/molecules/Select';
import FlexBox from 'components/wrappers/FlexBox';

const TallMarginBottom = styled.div`
  margin-bottom: 30px;
`;

const ShortMarginBottom = styled.div`
  margin-bottom: 20px;
`;

const Hyphen = styled(FlexBox)`
  margin: 0 5px;
  color: ${color[COLOR.GRAY][500]};
`;

const LeftMargin = styled.div`
  margin-left: 10px;
`;

const SearchButtonMargin = styled.div`
  margin: 0 200px 0 10px;
`;

export default function DomainRegistrationForm(props) {
  const {
    setAlert,
    individualOrInstitution,
    ownerOrManager,
    koreanName,
    setKoreanName,
    englishName,
    setEnglishName,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    setVerificationCode,
    verificationCodeInput,
    setVerificationCodeInput,
    isEmailVerified,
    setIsEmailVerified,
    address,
    setAddress,
    registrationCertificateType,
    setRegistrationCertificateType,
    registrationCertificate,
    setRegistrationCertificate,
    institutionKoreanName,
    setInstitutionKoreanName,
    institutionEnglishName,
    setInstitutionEnglishName,
    verifyCodeInput,
  } = props;
  const [certificatePlaceholder, setCertificatePlaceholder] = useState('');

  useEffect(() => {
    switch (registrationCertificateType) {
      case '사업자등록증':
        setCertificatePlaceholder('예) 220-88-41563');
        break;
      case '개인 사업자등록증':
        setCertificatePlaceholder('예) 220-88-41563');
        break;
      case '법인등록증':
        setCertificatePlaceholder('예) 110111-1777585');
        break;
      case '고유번호증':
        setCertificatePlaceholder('예) 202-82-00000');
        break;
      default:
        setCertificatePlaceholder('');
    }
  }, [registrationCertificateType]);

  function handleKoreanNameChange(event) {
    setKoreanName(event.target.value);
  }

  function handleEnglishNameChange(event) {
    setEnglishName(event.target.value);
  }

  function handleEmailChange(event) {
    setVerificationCode('');
    setIsEmailVerified(false);
    setEmail(event.target.value);
  }

  function handleEmailKeyPress(event) {
    if (event.key === 'Enter') {
      handleRequestVerificationCodeClick();
    }
  }

  function handleVerificationCodeInputChange(event) {
    setVerificationCodeInput(event.target.value);
  }

  function handleRegistrationCertificateChange(event) {
    setRegistrationCertificate(event.target.value);
  }

  function handleInstitutionKoreanNameChange(event) {
    setInstitutionKoreanName(event.target.value);
  }

  function handleInstitutionEnglishNameChange(event) {
    setInstitutionEnglishName(event.target.value);
  }

  function handleSearchAddress() {
    // eslint-disable-next-line no-undef
    new daum.Postcode({
      oncomplete: (data) => {
        setAddress((prev) => {
          const copy = { ...prev };

          copy.zipCode = data.zonecode;
          copy.address = data.address;
          copy.addressEnglish = data.addressEnglish;
          copy.roadAddress = data.autoRoadAddress || data.roadAddress;
          copy.roadAddressEnglish = data.autoRoadAddressEnglish || data.roadAddressEnglish;
          copy.bname = data.bname;
          copy.bnameEnglish = data.bnameEnglish;
          copy.jibunAddress = data.autoJibunAddress || data.jibunAddress;
          copy.jibunAddressEnglish = data.autoJibunAddressEnglish || data.jibunAddressEnglish;

          return copy;
        });
      },
    }).open();
  }

  async function handleRequestVerificationCodeClick() {
    if (!emailValidator.validate(email)) {
      setAlert('이메일 형식이 올바르지 않습니다. 확인 후 다시 입력해주시기 바랍니다.');

      return;
    }

    setIsEmailVerified(false);
    setAlert('입력하신 주소로 본인 인증을 위한 메일이 발신되었습니다.');

    const response = await requestVerificationCode(email);

    setVerificationCode(atob(response.key));
  }

  function handleVerifyEmailCodeClick() {
    setIsEmailVerified(false);
    verifyCodeInput();
  }

  function handleRegistrationCertificateBlur() {
    setRegistrationCertificate((input) => removeSpaces(input));
  }

  function handleKoreanNameBlur() {
    setKoreanName((input) => removeSpaces(input));
  }

  return (
    <>
      {individualOrInstitution === 'institution' && (
        <>
          <TallMarginBottom>
            <FlexBox>
              <Title>기관등록증 구분</Title>
              <Select
                width='100%'
                selected={registrationCertificateType}
                options={[
                  '사업자등록증',
                  '개인 사업자등록증',
                  '법인등록증',
                  '고유번호증',
                  '학교인가증',
                  '기타',
                ]}
                setFunction={setRegistrationCertificateType}
              />
            </FlexBox>
          </TallMarginBottom>
          <TallMarginBottom>
            <InputWithTitleOnSide
              title='기관등록증 번호'
              inputPlaceholder={certificatePlaceholder}
              inputValue={registrationCertificate}
              onInputChange={handleRegistrationCertificateChange}
              onBlur={handleRegistrationCertificateBlur}
            />
          </TallMarginBottom>
          <TallMarginBottom>
            <InputWithTitleOnSide
              title='기관이름(한글)'
              inputPlaceholder='예) 식스샵'
              inputValue={institutionKoreanName}
              onInputChange={handleInstitutionKoreanNameChange}
            />
          </TallMarginBottom>
          <TallMarginBottom>
            <InputWithTitleOnSide
              title='기관이름(영어)'
              inputPlaceholder='예) Sixshop'
              inputValue={institutionEnglishName}
              onInputChange={handleInstitutionEnglishNameChange}
            />
          </TallMarginBottom>
        </>
      )}
      <TallMarginBottom>
        <InputWithTitleOnSide
          title={ownerOrManager === 'owner' ? '소유자명(한글)' : '관리자명(한글)'}
          inputPlaceholder='예) 홍길동'
          inputValue={koreanName}
          onInputChange={handleKoreanNameChange}
          onBlur={handleKoreanNameBlur}
        />
      </TallMarginBottom>
      <TallMarginBottom>
        <InputWithTitleOnSide
          title={ownerOrManager === 'owner' ? '소유자명(영어)' : '관리자명(영어)'}
          inputPlaceholder='예) Gildong Hong'
          inputValue={englishName}
          onInputChange={handleEnglishNameChange}
        />
      </TallMarginBottom>
      <TallMarginBottom>
        <InputWithTitleOnSide
          title='휴대전화'
          inputValue={phoneNumber.head}
          onInputChange={(event) => {
            setPhoneNumber((prev) => {
              const copy = { ...prev };
              copy.head = event.target.value;
              return copy;
            });
          }}
          onBlur={() => {
            setPhoneNumber((prev) => {
              const copy = { ...prev };
              copy.head = removeSpaces(prev.head);
              return copy;
            });
          }}
        >
          <Hyphen>-</Hyphen>
          <Input
            value={phoneNumber.middle}
            onChange={(event) => {
              setPhoneNumber((prev) => {
                const copy = { ...prev };
                copy.middle = event.target.value;
                return copy;
              });
            }}
            onBlur={() => {
              setPhoneNumber((prev) => {
                const copy = { ...prev };
                copy.middle = removeSpaces(prev.middle);
                return copy;
              });
            }}
          />
          <Hyphen>-</Hyphen>
          <Input
            value={phoneNumber.tail}
            onChange={(event) => {
              setPhoneNumber((prev) => {
                const copy = { ...prev };
                copy.tail = event.target.value;
                return copy;
              });
            }}
            onBlur={() => {
              setPhoneNumber((prev) => {
                const copy = { ...prev };
                copy.tail = removeSpaces(prev.tail);
                return copy;
              });
            }}
          />
        </InputWithTitleOnSide>
      </TallMarginBottom>
      <ShortMarginBottom>
        <InputWithTitleOnSide
          title='이메일'
          inputPlaceholder='예) help@sixshop.com'
          inputValue={email}
          onInputChange={handleEmailChange}
          onInputKeyPress={handleEmailKeyPress}
        >
          <LeftMargin>
            <GrayButton onClick={handleRequestVerificationCodeClick}>인증코드 받기</GrayButton>
          </LeftMargin>
        </InputWithTitleOnSide>
      </ShortMarginBottom>
      <TallMarginBottom>
        <InputWithTitleOnSide
          inputPlaceholder='인증코드를 입력해 주세요'
          inputValue={verificationCodeInput}
          onInputChange={handleVerificationCodeInputChange}
          onInputKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleVerifyEmailCodeClick();
            }
          }}
          isChecked={isEmailVerified}
        >
          <LeftMargin>
            <PurpleButton onClick={handleVerifyEmailCodeClick}>인증코드 확인</PurpleButton>
          </LeftMargin>
        </InputWithTitleOnSide>
      </TallMarginBottom>
      <ShortMarginBottom>
        <InputWithTitleOnSide
          title='도로명주소'
          inputPlaceholder='우편번호를 검색해 주세요'
          isInputDisabled
          inputValue={address.zipCode}
        >
          <SearchButtonMargin>
            <WhiteButton onClick={handleSearchAddress}>검색하기</WhiteButton>
          </SearchButtonMargin>
        </InputWithTitleOnSide>
      </ShortMarginBottom>
      <ShortMarginBottom>
        <InputWithTitleOnSide
          inputPlaceholder='예) 서울특별시 서초구 강남대로 311'
          isInputDisabled
          inputValue={address.address}
        />
      </ShortMarginBottom>
      <InputWithTitleOnSide
        inputPlaceholder='상세주소를 입력해 주세요'
        inputValue={address.additionalAddress}
        onInputChange={(event) =>
          setAddress((prev) => {
            const copy = { ...prev };
            copy.additionalAddress = event.target.value;
            return copy;
          })
        }
      />
    </>
  );
}

DomainRegistrationForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  individualOrInstitution: PropTypes.oneOf(['individual', 'institution']).isRequired,
  ownerOrManager: PropTypes.oneOf(['owner', 'manager']).isRequired,
  koreanName: PropTypes.string.isRequired,
  setKoreanName: PropTypes.func.isRequired,
  englishName: PropTypes.string.isRequired,
  setEnglishName: PropTypes.func.isRequired,
  phoneNumber: PropTypes.shape({
    head: PropTypes.string.isRequired,
    middle: PropTypes.string.isRequired,
    tail: PropTypes.string.isRequired,
  }).isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setVerificationCode: PropTypes.func.isRequired,
  verificationCodeInput: PropTypes.string.isRequired,
  setVerificationCodeInput: PropTypes.func.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  setIsEmailVerified: PropTypes.func.isRequired,
  address: PropTypes.shape({
    zipCode: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    additionalAddress: PropTypes.string.isRequired,
  }).isRequired,
  setAddress: PropTypes.func.isRequired,
  registrationCertificateType: PropTypes.string.isRequired,
  setRegistrationCertificateType: PropTypes.func.isRequired,
  registrationCertificate: PropTypes.string.isRequired,
  setRegistrationCertificate: PropTypes.func.isRequired,
  institutionKoreanName: PropTypes.string.isRequired,
  setInstitutionKoreanName: PropTypes.func.isRequired,
  institutionEnglishName: PropTypes.string.isRequired,
  setInstitutionEnglishName: PropTypes.func.isRequired,
  verifyCodeInput: PropTypes.func.isRequired,
};
