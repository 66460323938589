import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';

import { QUERY_KEY } from 'constants/queryKey';
import { color, COLOR } from 'styles/colors';
import IcoCloseLineBig from 'icons/IcoCloseLineBig';
import requestAddDomain from 'api/domain/requestAddDomain';
import trimHyphens from 'utils/domain/trimHyphens';
import IcoQuestionCircleLine from 'icons/IcoQuestionCircleLine';
import { LargeGrayButton, LargePurpleButton } from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Alert from 'components/atoms/Alert';
import MainModal from 'components/molecules/MainModal';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import FlexBox from 'components/wrappers/FlexBox';
import removePrefixes from 'utils/domain/removePrefixes';

const Header = styled(FlexBox)`
  position: relative;
  justify-content: space-between;
  padding: 26px 30px;
  border-bottom: 1px solid ${color[COLOR.GRAY][200]};

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  max-height: calc(95vh - 192px);
  padding: 24px 30px 0;
  overflow: auto;
`;

const Content = styled.div`
  margin-bottom: 26px;

  h6 {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5em;
  }
`;

const ContentHeader = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 16px;

  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5em;
  }

  a {
    display: flex;
    align-items: center;
    color: ${color[COLOR.PURPLE][500]};
    font-size: 14px;
    text-decoration: none;
  }
`;

const ContentBody = styled.div`
  width: 100%;
  padding: 15px 14px 0;
  font-size: 14px;
  border: 1px solid ${color[COLOR.GRAY][300]};
  border-radius: 10px;
`;

const NsDataText = styled.div`
  margin-bottom: 15px;
  padding: 0 5px;
  color: ${color[COLOR.GRAY][500]};
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
`;

const NsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.33fr 9fr;
  margin-bottom: 15px;
  padding: 0 5px;
  font-size: 13px;
`;

const SubText = styled.span`
  color: ${color[COLOR.GRAY][500]};
  font-size: 10px;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 28px 35px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 5px;
`;

export default function DomainInputModal(props) {
  const { isOpen, setIsOpen, isPending, setIsPending } = props;
  const [input, setInput] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [domainInfo, setDomainInfo] = useState(null);
  const ordinalNumbers = ['1st', '2nd', '3rd', '4th'];
  const queryClient = useQueryClient();
  const { mutate: mutateDomains } = useMutation(
    () => {
      setAlertMessage('');
      setIsPending(true);
      const processedInput = trimHyphens(removePrefixes(input));
      const regexSpecialChars = /[!$%^&*()_+|~=`{}[\]:";'<>?,/]/;

      if (regexSpecialChars.test(processedInput)) {
        setAlertMessage('도메인에 하이픈(-)을 제외한 특수문자는 포함할 수 없습니다.');

        return;
      }

      return requestAddDomain(processedInput);
    },
    {
      onSettled: (data) => {
        setIsPending(false);

        if (!data) return;

        switch (data.RESULT) {
          case 'ALREADY_REGISTERED_DOMAIN_ERROR':
            setAlertMessage('이미 등록되어 있는 도메인입니다.');
            break;
          case 'REACH_DOMAIN_LIMIT_ERROR':
            setAlertMessage('도메인은 최대 5개까지 등록할 수 있습니다.');
            break;
          case 'NOT_ALLOWED_DOMAIN_ERROR':
            setAlertMessage('sixshop 도메인은 사용하실 수 없습니다.');
            break;
          case 'PLEASE_UPGRADE_PLAN_ERROR':
            setAlertMessage('Trial 요금제에서는 도메인을 등록할 수 없습니다.');
            break;
          case 'InvalidDomainName':
            setAlertMessage('사용할 수 없는 도메인입니다.');
            break;
          case 'NOT_LOGIN_ERROR':
            setAlertMessage('로그인을 다시 해주세요.');
            break;
          default:
            setInput((prev) => removePrefixes(prev));
            setDomainInfo(data.domain);
            queryClient.invalidateQueries(QUERY_KEY.DOMAINS);
        }
      },
    }
  );

  function handleInputChange(event) {
    setInput(event.target.value);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleCloseAlert() {
    setAlertMessage('');
  }

  function handleNameServerHostClick(event) {
    navigator.clipboard.writeText(event.target.getAttribute('value').trim());
  }

  function handleSubmit() {
    if (!input) {
      setAlertMessage('도메인을 입력해 주세요.');

      return;
    }

    mutateDomains();
  }

  return (
    <MainModal toClose={closeModal} isOpen={isOpen}>
      <Header>
        <h4>도메인 추가하기</h4>
        <PointerOnHoverWrapper onClick={closeModal}>
          <IcoCloseLineBig width={20} height={20} color={color[COLOR.BLACK]} />
        </PointerOnHoverWrapper>
      </Header>
      {alertMessage && (
        <Alert
          text={alertMessage}
          top='79px'
          fontSize='15px'
          padding='15px 30px'
          closeFunction={handleCloseAlert}
        />
      )}
      <ContentWrapper>
        <Content>
          <h6>도메인 주소</h6>
          <Input
            $width='100%'
            type='text'
            placeholder='예) domain.com'
            value={input}
            onChange={handleInputChange}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSubmit();
              }
            }}
            autoFocus
            disabled={isPending || !!domainInfo}
          />
        </Content>
        {domainInfo && (
          <Content>
            <ContentHeader>
              <h6>네임서버 정보</h6>
              <a href='https://help.sixshop.com/learn-sixshop/get-started/prepare-to-sell/domains'>
                <IcoQuestionCircleLine color={color[COLOR.PURPLE][500]} />
                &nbsp; 네임서버 설정 방법 보기
              </a>
            </ContentHeader>
            <ContentBody>
              <NsDataText>
                도메인 공급업체의 웹사이트에 접속하여 네임서버 설정을 변경해주세요.
                <br />
                도메인이 완전히 연결되려면 48시간이 걸립니다.
              </NsDataText>
              <NsRow>
                <span>구분</span>
                <span>IP주소</span>
                <span>
                  호스트명&nbsp;<SubText>호스트명 클릭 시 클립보드로 복사됩니다.</SubText>
                </span>
              </NsRow>
              {ordinalNumbers.map((ordinalNumber, index) => {
                const nameServers = domainInfo.nameServers.split(',');
                const nameServer = nameServers[index].replace('[', '').replace(']', '');

                return (
                  <NsRow key={ordinalNumber}>
                    <Bold>{ordinalNumber}</Bold>
                    <span>{domainInfo.nameServerIps[index]}</span>
                    <PointerOnHoverWrapper value={nameServer} onClick={handleNameServerHostClick}>
                      {nameServer}
                    </PointerOnHoverWrapper>
                  </NsRow>
                );
              })}
            </ContentBody>
          </Content>
        )}
        <ButtonsWrapper>
          {domainInfo ? (
            <LargePurpleButton onClick={closeModal}>확인</LargePurpleButton>
          ) : (
            <>
              <ButtonWrapper>
                <LargeGrayButton onClick={closeModal}>취소하기</LargeGrayButton>
              </ButtonWrapper>
              <ButtonWrapper>
                <LargePurpleButton onClick={handleSubmit} disabled={isPending}>
                  추가하기
                </LargePurpleButton>
              </ButtonWrapper>
            </>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </MainModal>
  );
}

DomainInputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  setIsPending: PropTypes.func.isRequired,
};
