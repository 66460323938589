import create from 'zustand';

const useNewDomainStore = create((set) => ({
  selectedDomain: '',
  period: 1,
  setSelectedDomain: (domain) => set((state) => ({ ...state, selectedDomain: domain })),
  setPeriod: (period) => set((state) => ({ ...state, period })),
}));

const useDomainExtensionStore = create((set) => ({
  extendingDomain: null,
  period: 1,
  setExtendingDomain: (domain) => set((state) => ({ ...state, extendingDomain: domain })),
  setPeriod: (period) => set((state) => ({ ...state, period })),
}));

export { useNewDomainStore, useDomainExtensionStore };
