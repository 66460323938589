import { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';

import requestLogout from 'api/member/requestLogout';
import { PATH } from 'constants/path';
import useMemberNo from 'hooks/useMemberNo';
import checkIsTokenExpired from 'utils/auth/checkIsTokenExpired';
import ChannelTalk from 'components/organisms/ChannelTalk';
import DomainPage from 'pages/store-setting/domain';

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const memberNo = useMemberNo();

  useEffect(() => {
    const isTokenExpired = checkIsTokenExpired();

    if (!memberNo || isTokenExpired) {
      setIsLoggedIn(false);
      logout();
    } else {
      setIsLoggedIn(true);
    }
  }, [memberNo]);

  async function logout() {
    await requestLogout();
    window.location.assign(
      `${
        process.env.NODE_ENV === 'development'
          ? 'https://store.sixshoptest.com/member/login'
          : '/member/login'
      }`
    );
  }

  return isLoggedIn ? (
    <>
      <Switch>
        <Route path={`${PATH.STORE_SETTING}${PATH.DOMAIN}`}>
          <DomainPage />
        </Route>
      </Switch>
      <ChannelTalk />
    </>
  ) : null;
}
