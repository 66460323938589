import { useState } from 'react';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import sixshopSymbol from 'static/sixshop-symbol.svg';
import IcoMenuLine from 'icons/IcoMenuLine';
import HamburgerMenuModal from 'components/organisms/HamburgerMenuModal';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import FlexBox from 'components/wrappers/FlexBox';

const Wrapper = styled(FlexBox)`
  position: relative;
  justify-content: space-between;
  width: 240px;
  padding: 15px 24px 7px 20px;
  line-height: 0;
  background-color: ${color[COLOR.WHITE]};
  border-right: 1px solid ${color[COLOR.GRAY][200]};
`;

export default function SidebarHeader() {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  function toggleHamburgerMenu() {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  }

  function handleHamburgerOuterClick() {
    if (!isHamburgerMenuOpen) return;

    toggleHamburgerMenu();
  }

  return (
    <Wrapper>
      <a href='/'>
        <img src={sixshopSymbol} alt='식스샵 로고' />
      </a>
      <PointerOnHoverWrapper onClick={toggleHamburgerMenu}>
        <IcoMenuLine width={20} height={20} color={color[COLOR.BLACK]} />
      </PointerOnHoverWrapper>
      <HamburgerMenuModal isOpen={isHamburgerMenuOpen} onOuterClick={handleHamburgerOuterClick} />
    </Wrapper>
  );
}
