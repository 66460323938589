import styled from 'styled-components';

import { color as colors, COLOR } from 'styles/colors';
import FlexBox from 'components/wrappers/FlexBox';

export const ContentsListItemWrapper = styled(FlexBox)`
  flex: ${({ flex }) => flex};
  height: 95px;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};

  &:first-child {
    border-top: 1px solid ${({ borderColor }) => borderColor};
  }
`;

export const ThumbnailWrapper = styled(FlexBox)`
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 20px 0;
`;

export const ContentWrapper = styled.div`
  flex: ${({ flex }) => flex || 1};
  height: auto;
  margin: 0 20px;
  line-height: 19px;
`;

export const MainTextWrapper = styled.div`
  color: ${colors[COLOR.BLACK]};
  font-weight: 600;
  font-size: 14px;
`;

export const SubTextWrapper = styled.div`
  position: relative;
  color: ${({ color }) => color || '#999'};
  font-size: 12px;
  line-height: 17px;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  flex: ${({ flex }) => flex};
  max-width: 200px;
  text-align: right;
`;
