import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import IcoTriangle from 'icons/IcoTriangle';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';

const Wrapper = styled(PointerOnHoverWrapper)`
  margin: 0 8px 0 8px;
  padding: 6px 16px 6px 8px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 14px;
  line-height: 24px;
  border-radius: 6px;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${color[COLOR.GRAY][100]};
  }
`;

const IcoTriangleWrapper = styled.span`
  margin-right: 5px;
  margin-left: -1px;
`;

export default function ToDashboard() {
  return (
    <Wrapper>
      <a href='/dashboard/shop-home'>
        <IcoTriangleWrapper>
          <IcoTriangle width={10} height={10} color={color[COLOR.GRAY][600]} direction='left' />
        </IcoTriangleWrapper>
        관리하기로 돌아가기
      </a>
    </Wrapper>
  );
}
