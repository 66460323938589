import { useQuery } from 'react-query';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { QUERY_KEY } from 'constants/queryKey';
import useMemberNo from 'hooks/useMemberNo';
import requestDomains from 'api/domain/requestDomains';
import DomainMain from 'components/terrains/DomainMain';
import StoreSettingsLayout from 'layouts/StoreSettingsLayout';
import DomainPaymentResult from 'pages/store-setting/domain/payment-result';
import DomainRegistration from 'pages/store-setting/domain/new/register';
import DomainSearch from 'pages/store-setting/domain/new/search';
import DomainExtendRegistration from './extend/register';

export default function DomainPage() {
  const { path } = useRouteMatch();
  const memberNo = useMemberNo();
  const { data: domainsResponse } = useQuery(QUERY_KEY.DOMAINS, () => requestDomains(memberNo), {
    enabled: !!memberNo,
  });
  const domains = domainsResponse?.domainList;

  return (
    <Switch>
      <Route exact path={path}>
        <StoreSettingsLayout currentSection='domain'>
          <Helmet>
            <title>Sixshop | 도메인</title>
          </Helmet>
          <DomainMain domains={domains} />
        </StoreSettingsLayout>
      </Route>
      <Route path={`${path}/new/search`}>
        <Helmet>
          <title>Sixshop | 구매하기</title>
        </Helmet>
        <DomainSearch />
      </Route>
      <Route path={`${path}/new/register`}>
        <Helmet>
          <title>Sixshop | 신청하기</title>
        </Helmet>
        <DomainRegistration />
      </Route>
      <Route path={`${path}/new/purchase-result`}>
        <DomainPaymentResult type='new' onMount={() => sessionStorage.removeItem('domain_temp')} />
      </Route>
      <Route path={`${path}/extend/register`}>
        <DomainExtendRegistration />
      </Route>
      <Route path={`${path}/extend/extend-result`}>
        <DomainPaymentResult type='extend' />
      </Route>
    </Switch>
  );
}
