import { useEffect } from 'react';

import ChannelService from 'services/ChannelService';
import useMemberInfo from 'hooks/useMemberInfo';

export default function ChannelTalk() {
  const memberInfo = useMemberInfo();

  useEffect(() => {
    if (!memberInfo) return;

    ChannelService.boot({
      pluginKey: process.env.REACT_APP_CHANNEL_SERVICE_PLUGIN_KEY,
      memberId: memberInfo.memberNo,
      profile: {
        memberNo: memberInfo.memberNo,
        name: memberInfo.userName,
        siteName: memberInfo.siteName,
        memberName: memberInfo.memberName,
        mobileNumber: memberInfo.mobileNumber,
        email: memberInfo.email,
      },
    });

    return () => ChannelService.shutdown();
  }, [memberInfo?.memberNo]);

  return null;
}
