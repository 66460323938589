import { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';

const fillIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const fillOut = keyframes`
  from { transform: scale(1); }
  to { transform: scale(0); }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const Indicator = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid
    ${({ isMouseOver }) => (isMouseOver ? color[COLOR.PURPLE][500] : color[COLOR.GRAY][300])};
  border-radius: 50%;
  display: grid;
  place-items: center;
`;

const IndicatorFill = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${color[COLOR.PURPLE][500]};
  border-radius: 50%;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  animation: ${css`
    ${({ isActive }) => (isActive ? fillIn : fillOut)} 200ms;
  `};
`;

const TextWrapper = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${({ isMouseOver }) => (isMouseOver ? color[COLOR.PURPLE][500] : '#666')};
  transition: all 130ms linear;
`;

export default function Radio(props) {
  const { name, value, isChecked, onChange, label } = props;
  const [isMouseOver, setIsMouseOver] = useState(false);

  function handleMouseOver() {
    setIsMouseOver(true);
  }

  function handleMouseOut() {
    setIsMouseOver(false);
  }

  return (
    <Label onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Input type='radio' name={name} value={value} checked={isChecked} onChange={onChange} />
      <Indicator isMouseOver={isMouseOver}>
        <IndicatorFill isActive={isChecked} />
      </Indicator>
      <TextWrapper isMouseOver={isMouseOver}>{label}</TextWrapper>
    </Label>
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

Radio.defaultProps = {
  value: '',
};
