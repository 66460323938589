import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import handleDnsModificationResponse from 'services/domain/handleDnsModificationResponse';
import { QUERY_KEY } from 'constants/queryKey';
import { color, COLOR } from 'styles/colors';
import replaceAll from 'utils/domain/replaceAll';
import requestAddDnsRecord from 'api/domain/requestAddDnsRecord';
import requestDomainDetails from 'api/domain/requestDomainDetails';
import Input from 'components/atoms/Input';
import Textarea from 'components/atoms/Textarea';
import { PurpleButton } from 'components/atoms/Button';
import Select from 'components/molecules/Select';
import DnsRecordRow from 'components/molecules/DnsRecordRow';

const Row = styled.div`
  display: grid;
  grid-gap: 7px;
  grid-template-columns: 1fr 1fr 5fr 75px;
  border-bottom: ${({ borderBottom }) => borderBottom};

  span {
    padding: 15px;
  }
`;

const NameInput = styled(Input)`
  padding: 15px;
`;

const ValueTextarea = styled(Textarea)`
  padding: 15px;
`;

export default function DnsRecordSettings(props) {
  const { domainNo, domainAddress, setAlertMessage } = props;

  const types = ['A', 'CNAME', 'MX', 'TXT'];
  const [type, setType] = useState(types[0]);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const queryClient = useQueryClient();
  const { data: domainDetailsResponse } = useQuery(
    QUERY_KEY.DOMAIN_DETAILS,
    () => requestDomainDetails(domainNo),
    {
      enabled: !!domainNo,
    }
  );
  const { mutate: addDnsRecord } = useMutation(
    () => {
      let dnsName = type === 'MX' || type === 'TXT' ? '' : name;
      dnsName = replaceAll(dnsName, `.${domainAddress}`, '');

      return requestAddDnsRecord(domainAddress, { type, name: dnsName, value });
    },
    {
      onSettled: (data) => {
        handleDnsModificationResponse(data, setAlertMessage);
        queryClient.invalidateQueries(QUERY_KEY.DOMAIN_DETAILS);
      },
    }
  );

  useEffect(() => {
    return () => queryClient.removeQueries(QUERY_KEY.DOMAIN_DETAILS);
  }, []);

  const dnsRecords = domainDetailsResponse?.domain?.resourceRecordSetList;

  function handleSubmit() {
    const regIpv4 =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (name === '@') {
      setAlertMessage(
        `Name에 @는 입력하실 수 없습니다.\n@는 공백으로 입력해주시면 됩니다.\n필요하신 경우 고객센터로 연락해주세요.`
      );

      return;
    }

    if (!value) {
      setAlertMessage('값을 입력해주세요.');

      return;
    }

    if (type === 'A' && !regIpv4.test(value)) {
      setAlertMessage('IP를 입력해주세요.');

      return;
    }

    if (type === 'TXT' && (value.charAt(0) !== '"' || value.charAt(value.length - 1) !== '"')) {
      setAlertMessage('TXT 값은 쌍따옴표(")로 시작하고 "로 끝나야 합니다.');

      return;
    }

    if (type === 'CNAME' && regIpv4.test(value)) {
      setAlertMessage('CNAME에는 IP를 입력할 수 없습니다.');

      return;
    }

    setAlertMessage('');
    addDnsRecord();
  }

  function handleNameChange(event) {
    setName(event.target.value);
  }

  function handleValueChange(event) {
    setValue(event.target.value);
  }

  return (
    <>
      <Row>
        <Select
          options={types}
          selected={type}
          setFunction={setType}
          fontSize='14px'
          fontWeight={400}
        />
        <NameInput placeholder='Name' value={name} onChange={handleNameChange} />
        <ValueTextarea
          rows={1}
          placeholder='Value'
          value={value}
          onChange={handleValueChange}
          resize='vertical'
        />
        <PurpleButton onClick={handleSubmit}>저장</PurpleButton>
      </Row>
      <Row borderBottom={dnsRecords?.length ? `1px solid ${color[COLOR.GRAY][350]}` : 'none'}>
        <span>Type</span>
        <span>Name</span>
        <span>Value</span>
      </Row>
      {dnsRecords?.map((record) => {
        if (record.type === 'NS' || record.type === 'SOA') return;

        return (
          <DnsRecordRow
            key={record.type + record.name + JSON.stringify(record.resourceRecords)}
            record={record}
            domainAddress={domainAddress}
            setAlertMessage={setAlertMessage}
          />
        );
      })}
    </>
  );
}

DnsRecordSettings.propTypes = {
  domainNo: PropTypes.number.isRequired,
  domainAddress: PropTypes.string.isRequired,
  setAlertMessage: PropTypes.func,
};

DnsRecordSettings.defaultProps = {
  setAlertMessage: () => {},
};
