import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';

const Input = styled.input`
  flex: 1;
  width: ${({ $width }) => $width};
  min-width: 0;
  max-width: ${({ maxWidth }) => maxWidth};
  height: 50px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 19px;
  border: ${({ border }) => border || `1px solid ${color[COLOR.GRAY][300]}`};
  border-radius: 10px;
`;

export default Input;
