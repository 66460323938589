import PropTypes from 'prop-types';

export default function IcoQuestionCircleLine(props) {
  const { color } = props;

  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 8a5.5 5.5 0 1011 0 5.5 5.5 0 00-11 0zM8 1a7 7 0 100 14A7 7 0 008 1zM5.75 6.2A2.25 2.25 0 118 8.45a.75.75 0 010-1.5.75.75 0 10-.75-.75.75.75 0 01-1.5 0zM8 12a1 1 0 100-2 1 1 0 000 2z'
        fill={color}
      />
      <path d='M8 7.7v1' stroke={color} strokeWidth={1.5} strokeLinecap='round' />
    </svg>
  );
}

IcoQuestionCircleLine.propTypes = {
  color: PropTypes.string.isRequired,
};
