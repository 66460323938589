export const PLAN = {
  admin: '관리자',
  superTrial: '무제한 체험판',
  trial: '체험판',
  homepage: '홈페이지',
  kakaoHomepage: '카카오 홈페이지',
  basic: '베이직',
  shoppingMall: '쇼핑몰',
  globalMall: '해외 쇼핑몰',
  premium: '프리미엄',
  kakaoUnlimited: '카카오 언리미티드',
  kakao: '카카오 프리미엄',
};
