import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';

const Wrapper = styled.div`
  padding-bottom: 36px;
`;

const Headline = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  text-transform: capitalize;
`;

const SubContentWrapper = styled.div`
  margin-top: 16px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 14px;
`;

export default function HeadlineHeader(props) {
  const { title, children } = props;

  return (
    <Wrapper>
      <Headline>{title}</Headline>
      {children && <SubContentWrapper>{children}</SubContentWrapper>}
    </Wrapper>
  );
}

HeadlineHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

HeadlineHeader.defaultProps = {
  children: null,
};
