import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(17, 17, 17, 0.8);
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: auto;
`;

export default Overlay;
