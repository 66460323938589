import styled from 'styled-components';
import PropTypes from 'prop-types';

import Scrollbar from 'components/atoms/Scrollbar';
import ContentWrapper from 'components/wrappers/EntireContentWrapper';
import StoreSettingsSidebar from 'components/terrains/StoreSettingsSidebar';

const Wrapper = styled.div`
  display: flex;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export default function StoreSettingsLayout(props) {
  const { currentSection, children } = props;

  return (
    <Wrapper>
      <StoreSettingsSidebar currentSection={currentSection} />
      <MainWrapper>
        <Scrollbar>
          <ContentWrapper>{children}</ContentWrapper>
        </Scrollbar>
      </MainWrapper>
    </Wrapper>
  );
}

StoreSettingsLayout.propTypes = {
  currentSection: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
