import PropTypes from 'prop-types';

import { color as colors, COLOR } from 'styles/colors';

export default function IcoWrongCircle(props) {
  const { size, color } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 8a7 7 0 1114 0A7 7 0 011 8zm6-3a1 1 0 012 0v3a1 1 0 01-2 0V5zm1 5a1 1 0 100 2 1 1 0 000-2z'
        fill={color}
      />
    </svg>
  );
}

IcoWrongCircle.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

IcoWrongCircle.defaultProps = {
  size: 16,
  color: colors[COLOR.RED][500],
};
