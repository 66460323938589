import { METHOD } from 'constants/method';

export default async function requestConnectedSitesInfo(memberNo) {
  const res = await fetch('/apis/user-home/site-connections', {
    method: METHOD.POST,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: new URLSearchParams({ memberNo }),
  });

  return res.json();
}
