export default async function requestDomainPaymentApproval(data) {
  const res = await fetch('/apis/internal/payments/success', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return res;
}
