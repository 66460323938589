import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color as colors } from 'styles/colors';

const Wrapper = styled.i`
  svg {
    transform: ${({ $transform }) => $transform};
  }
`;

const rotate = (direction) => {
  switch (direction) {
    case 'left':
      return 'rotate(90deg)';
    case 'up':
      return 'rotate(180deg)';
    case 'right':
      return 'rotate(-90deg)';
    default:
      return 'rotate(0deg)';
  }
};

export default function IcoTriangle(props) {
  const { width, height, color, direction } = props;

  return (
    <Wrapper $transform={rotate(direction)}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask id='prefix__a' maskUnits='userSpaceOnUse' x={0} y={0} width={width} height={height}>
          <path fill='#C4C4C4' d='M0 0h8v8H0z' />
        </mask>
        <g mask='url(#prefix__a)'>
          <path
            d='M4.386 6.532a.5.5 0 01-.772 0L.344 2.568A.5.5 0 01.73 1.75h6.54a.5.5 0 01.385.818l-3.27 3.964z'
            fill={color}
          />
        </g>
      </svg>
    </Wrapper>
  );
}

IcoTriangle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  direction: PropTypes.string,
};

IcoTriangle.defaultProps = {
  width: 10,
  height: 10,
  color: colors.black,
  direction: 'down',
};
