import { METHOD } from 'constants/method';
import { API_PATH } from 'constants/path';

export default async function requestChangeMainDomain(domainNo) {
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const { id: memberNo } = authentication;

  const res = await fetch(`${API_PATH.SIXSHOP2_DOMAIN}${API_PATH.SET_MAIN_DOMAIN}`, {
    method: METHOD.POST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      memberNo,
    },
    body: new URLSearchParams({
      memberNo,
      domainNo,
    }),
  });

  return res.json();
}
