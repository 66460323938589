import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';
import NavIco from 'icons/NavIco';
import { PATH } from 'constants/path';
import icoD from 'static/icoDs';
import Scrollbar from 'components/atoms/Scrollbar';
import NavTab from 'components/molecules/NavTab';
import ToDashboard from 'components/molecules/ToDashboard';
import SidebarHeader from 'components/organisms/SidebarHeader';
import MemberInfo from 'components/organisms/MemberInfo';
import SidebarWrapper from 'components/wrappers/SidebarWrapper';

const HeaderWrapper = styled.div`
  z-index: 9;
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom && `1px solid ${color[COLOR.GRAY][200]}`};
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: calc(100vh - 105px);
  overflow: auto;
`;

const NavTabWrapper = styled.div`
  padding: 0 8px;
`;

const ToDashboardWrapper = styled.div`
  margin: 25px 0 15px;
`;

const MemberInfoWrapper = styled.div`
  width: 240px;
  border-top: 1px solid ${color[COLOR.GRAY][200]};
`;

const navInfos = [
  { nav: 'basic', ico: 'shopSetting', text: '기본 정보' },
  { nav: 'operatorsInfo', ico: 'operatorsInfo', text: '법적 필수 정보' },
  { nav: 'agreement', ico: 'document', text: '이용약관' },
  { nav: 'payment', ico: 'card', text: '결제' },
  { nav: 'shipment', ico: 'shipment', text: '배송' },
  { nav: 'email', ico: 'mail', text: '이메일' },
  { nav: 'domain', ico: 'domain', text: '도메인' },
  { nav: 'commonApiKey', ico: 'api', text: 'API' },
];

export default function StoreSettingsSidebar(props) {
  const { currentSection } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMouseInBody, setIsMouseInBody] = useState(true);

  function handleBodyMouseOver() {
    setIsMouseInBody(true);
  }

  function handleBodyMouseOut() {
    setIsMouseInBody(false);
  }

  function handleScroll(values) {
    if (!values.top) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  }

  return (
    <SidebarWrapper>
      <HeaderWrapper hasBorderBottom={isScrolled}>
        <SidebarHeader />
      </HeaderWrapper>
      <BodyWrapper onMouseOver={handleBodyMouseOver} onMouseOut={handleBodyMouseOut}>
        <Scrollbar onScrollFrame={handleScroll} isActive={isMouseInBody}>
          <ToDashboardWrapper>
            <ToDashboard />
          </ToDashboardWrapper>
          {navInfos.map((navInfo) => (
            <NavTabWrapper key={navInfo.nav}>
              <a href={`${PATH.STORE_SETTING}/${navInfo.nav}`}>
                <NavTab
                  icon={
                    <NavIco
                      color={
                        currentSection === navInfo.nav
                          ? color[COLOR.PURPLE][500]
                          : color[COLOR.GRAY][400]
                      }
                      d={icoD[navInfo.ico]}
                    />
                  }
                  isCurrentSection={currentSection === navInfo.nav}
                >
                  {navInfo.text}
                </NavTab>
              </a>
            </NavTabWrapper>
          ))}
        </Scrollbar>
      </BodyWrapper>
      <MemberInfoWrapper>
        <MemberInfo />
      </MemberInfoWrapper>
    </SidebarWrapper>
  );
}

StoreSettingsSidebar.propTypes = {
  currentSection: PropTypes.string.isRequired,
};
