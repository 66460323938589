import { ALERT_MESSAGE } from 'constants/domain';
import removePrefixes from 'utils/domain/removePrefixes';

export default function validateDomainName(input) {
  const domainName = removePrefixes(input).replaceAll(' ', '').split('.')[0];
  const allowedCharsRegex = /^[가-힣a-zA-Z0-9-]+$/;
  const includesKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(domainName);

  if (!input || !domainName) {
    return {
      ok: false,
      message: ALERT_MESSAGE.NO_INPUT,
    };
  }

  if (!allowedCharsRegex.test(domainName)) {
    return {
      ok: false,
      message: ALERT_MESSAGE.INVALID_INPUT,
    };
  }

  if (
    domainName.length < 2 ||
    (includesKorean && domainName.length > 16) ||
    (!includesKorean && domainName.length > 60)
  ) {
    return {
      ok: false,
      message: ALERT_MESSAGE.INVALID_INPUT,
    };
  }

  return {
    ok: true,
    domainName,
  };
}
