import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { QUERY_KEY } from 'constants/queryKey';
import { RESPONSE_RESULT } from 'constants/responseResult';
import { color, COLOR } from 'styles/colors';
import requestChangeMainDomain from 'api/domain/requestChangeMainDomain';
import requestRemoveDomain from 'api/domain/requestRemoveDomain';
import requestLogout from 'api/member/requestLogout';
import IcoTriangle from 'icons/IcoTriangle';
import DomainIcon from 'icons/DomainIcon';
import IcoWrongGray from 'icons/IcoWrongGray';
import Dropdown from 'components/atoms/Dropdown';
import { SplitGrayLeftButton, SplitGrayRightButton } from 'components/atoms/Button';
import PromptModal from 'components/molecules/PromptModal';
import {
  ContentsListItemWrapper,
  ContentWrapper,
  MainTextWrapper,
  SubTextWrapper,
  ButtonWrapper,
} from 'components/wrappers/ContentsListItem';

const MainDomainText = styled.span`
  color: ${color[COLOR.PURPLE][500]};
  font-weight: 400;
  font-size: 14px;
`;

export default function MyDomainListItem(props) {
  const { domainInfo, onSettingsClick, onExtendClick } = props;
  const {
    domainNo,
    domainAddress,
    isMain,
    sixshop: isPurchasedFromSixshop,
    createdDate,
    expireDate,
    isConnected,
  } = domainInfo;
  const isPaymentOkRegisterOk = isPurchasedFromSixshop && expireDate;
  const isPaymentOkRegisterFail = !domainNo;
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isDeletePromptModalOpen, setIsDeletePromptModalOpen] = useState(false);

  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutate: mutateDomainsOnRemove } = useMutation(() => requestRemoveDomain(domainAddress), {
    onMutate: async () => {
      await queryClient.cancelQueries(QUERY_KEY.DOMAINS);

      const prevResponse = queryClient.getQueryData(QUERY_KEY.DOMAINS);
      const updatedDomains = prevResponse.domainList.filter(
        (domain) => domain.domainNo !== domainNo
      );

      queryClient.setQueryData(QUERY_KEY.DOMAINS, {
        domainList: updatedDomains,
      });

      return { prevResponse };
    },
    onSuccess: async (data) => {
      if (data.RESULT === RESPONSE_RESULT.NOT_LOGIN_ERROR) {
        await requestLogout();
        window.location.assign('/member/login');
      }

      queryClient.invalidateQueries(QUERY_KEY.DOMAINS);
    },
    onError: () => {
      queryClient.invalidateQueries(QUERY_KEY.DOMAINS);
    },
  });
  const { mutate: mutateDomainsOnMainChange } = useMutation(
    () => requestChangeMainDomain(domainInfo.domainNo),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(QUERY_KEY.DOMAINS);

        const prevResponse = queryClient.getQueryData(QUERY_KEY.DOMAINS);
        const domainsCopy = [...prevResponse.domainList];

        const prevMain = domainsCopy.find((domain) => domain.isMain);
        const futureMain = domainsCopy.find((domain) => domain.domainNo === domainNo);

        prevMain.isMain = 0;
        futureMain.isMain = 1;

        queryClient.setQueryData(QUERY_KEY.DOMAINS, {
          domainList: domainsCopy,
        });

        return { prevResponse };
      },
      onSuccess: async (data) => {
        if (data.RESULT === RESPONSE_RESULT.NOT_LOGIN_ERROR) {
          await requestLogout();
          window.location.assign('/member/login');
        }

        queryClient.invalidateQueries(QUERY_KEY.DOMAINS);
      },
      onError: () => {
        queryClient.invalidateQueries(QUERY_KEY.DOMAINS);
      },
    }
  );

  function handleSettingsClick() {
    onSettingsClick();
  }

  function handleExtendDomainClick() {
    onExtendClick({ domainNo, domainAddress });

    history.push(`domain/extend/register`);
  }

  function handleRemoveDomainClick() {
    setIsDeletePromptModalOpen(true);
    setIsDropdownActive(false);
  }

  function handleRemoveDomainConfirm() {
    mutateDomainsOnRemove();
    setIsDeletePromptModalOpen(false);
  }

  function handleMainDomainChange() {
    if (domainInfo.isMain) return;

    mutateDomainsOnMainChange();
    setIsDropdownActive(false);
  }

  function toggleDropdown() {
    setIsDropdownActive(!isDropdownActive);
  }

  function closeDeleteDomainPrompt() {
    setIsDeletePromptModalOpen(false);
  }

  return (
    <>
      <ContentsListItemWrapper borderColor={color[COLOR.GRAY][300]}>
        {isPaymentOkRegisterFail ? <IcoWrongGray /> : <DomainIcon />}
        <ContentWrapper>
          <MainTextWrapper>
            {domainAddress}
            {!!isMain && <MainDomainText>{' (대표 도메인)'}</MainDomainText>}
          </MainTextWrapper>
          {isPaymentOkRegisterOk && (
            <SubTextWrapper>
              {isConnected
                ? `이용기간: ${createdDate} ~ ${expireDate}`
                : '연결 중입니다. 웹사이트 접속까지 최대 48시간 정도 소요될 수 있습니다. (연결 완료 후 해당 도메인의 이용 기간이 노출됩니다.)'}
            </SubTextWrapper>
          )}
          {isPaymentOkRegisterFail && (
            <SubTextWrapper color={color[COLOR.RED][500]}>
              결제는 승인되었으나 주문 처리가 정상적으로 이루어지지 않았습니다. 식스샵 고객센터로
              연락 바랍니다.
            </SubTextWrapper>
          )}
        </ContentWrapper>
        <ButtonWrapper>
          <SplitGrayLeftButton
            onClick={handleSettingsClick}
            disabled={isPaymentOkRegisterFail || !isConnected}
          >
            설정하기
          </SplitGrayLeftButton>
          <SplitGrayRightButton
            onClick={toggleDropdown}
            disabled={isPaymentOkRegisterFail || !isConnected}
          >
            <IcoTriangle
              width={9.5}
              height={10}
              color={color[COLOR.PURPLE][600]}
              direction='down'
            />
          </SplitGrayRightButton>
          <Dropdown isActive={isDropdownActive} onOuterClick={toggleDropdown}>
            <button type='button' onClick={handleMainDomainChange}>
              대표 도메인으로 지정하기
            </button>
            {isPurchasedFromSixshop ? (
              <button type='button' onClick={handleExtendDomainClick}>
                연장하기
              </button>
            ) : (
              <button type='button' onClick={handleRemoveDomainClick}>
                삭제하기
              </button>
            )}
          </Dropdown>
        </ButtonWrapper>
      </ContentsListItemWrapper>
      {isDeletePromptModalOpen && (
        <PromptModal
          toClose={closeDeleteDomainPrompt}
          isOpen={isDeletePromptModalOpen}
          mainMessage='도메인을 삭제하시겠습니까?'
          subMessage='삭제할 경우 복구가 불가능합니다.'
          confirmText='삭제하기'
          cancelText='취소하기'
          onConfirm={handleRemoveDomainConfirm}
        />
      )}
    </>
  );
}

MyDomainListItem.propTypes = {
  domainInfo: PropTypes.shape({
    domainAddress: PropTypes.string.isRequired,
    domainNo: PropTypes.number.isRequired,
    createdDate: PropTypes.string,
    expireDate: PropTypes.string,
    isMain: PropTypes.number.isRequired,
    memberNo: PropTypes.number.isRequired,
    nameServers: PropTypes.string.isRequired,
    sixshop: PropTypes.bool,
    isConnected: PropTypes.bool,
  }).isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onExtendClick: PropTypes.func.isRequired,
};
