const icoD = {
  api: 'M12 2C12 3.10457 11.1046 4 10 4C9.6683 4 9.35545 3.91925 9.08005 3.77633L5.02744 7.25H11.1454C11.4421 6.51704 12.1607 6 13 6C14.1046 6 15 6.89543 15 8C15 9.10457 14.1046 10 13 10C12.1607 10 11.4421 9.48296 11.1454 8.75H5.02744L9.08005 12.2237C9.35545 12.0808 9.6683 12 10 12C11.1046 12 12 12.8954 12 14C12 15.1046 11.1046 16 10 16C8.89543 16 8 15.1046 8 14C8 13.7771 8.03647 13.5627 8.10376 13.3625L3.91995 9.77633C3.64455 9.91925 3.3317 10 3 10C1.89543 10 1 9.10457 1 8C1 6.89543 1.89543 6 3 6C3.3317 6 3.64455 6.08075 3.91995 6.22367L8.10376 2.63754C8.03647 2.4373 8 2.22291 8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2Z',
  card: 'M3 2.5C1.89543 2.5 1 3.39543 1 4.5V5.75H15V4.5C15 3.39543 14.1046 2.5 13 2.5H3ZM15 7.25H1V11.5C1 12.6046 1.89543 13.5 3 13.5H13C14.1046 13.5 15 12.6046 15 11.5V7.25Z',
  document:
    'M14 13C14 14.1046 13.1046 15 12 15H4C2.89543 15 2 14.1046 2 13V3C2 1.89543 2.89543 1 4 1H12C13.1046 1 14 1.89543 14 3V13ZM5 3.75C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H11C11.4142 5.25 11.75 4.91421 11.75 4.5C11.75 4.08579 11.4142 3.75 11 3.75H5ZM4.25 8C4.25 7.58579 4.58579 7.25 5 7.25H11C11.4142 7.25 11.75 7.58579 11.75 8C11.75 8.41421 11.4142 8.75 11 8.75H5C4.58579 8.75 4.25 8.41421 4.25 8ZM5 10.75C4.58579 10.75 4.25 11.0858 4.25 11.5C4.25 11.9142 4.58579 12.25 5 12.25H8C8.41421 12.25 8.75 11.9142 8.75 11.5C8.75 11.0858 8.41421 10.75 8 10.75H5Z',
  domain:
    'M6.06214 1.27169C3.37062 2.04541 1.34414 4.39047 1.03955 7.25003H4.0229C4.15902 5.04268 4.89188 2.99678 6.06214 1.27169ZM9.93755 1.27169C11.1078 2.99678 11.8407 5.04268 11.9768 7.25003H14.9601C14.6555 4.39047 12.6291 2.04542 9.93755 1.27169ZM14.9601 8.75003H11.9768C11.8407 10.9574 11.1078 13.0033 9.93755 14.7284C12.6291 13.9546 14.6555 11.6096 14.9601 8.75003ZM6.06214 14.7284C4.89188 13.0033 4.15902 10.9574 4.0229 8.75003H1.03955C1.34414 11.6096 3.37061 13.9547 6.06214 14.7284ZM7.99984 14.8011C6.59227 13.147 5.6886 11.0508 5.52622 8.75003H10.4735C10.3111 11.0508 9.40742 13.147 7.99984 14.8011ZM7.99984 1.19897C6.59227 2.85308 5.6886 4.94922 5.52622 7.25003H10.4735C10.3111 4.94922 9.40742 2.85308 7.99984 1.19897Z',
  mail: 'M1.05446 4.03449C1.01885 4.18386 1 4.33973 1 4.5V11.5C1 12.6046 1.89543 13.5 3 13.5H13C14.1046 13.5 15 12.6046 15 11.5V4.5C15 4.21908 14.9421 3.95169 14.8375 3.70911L8.50871 9.5511C8.22918 9.80913 7.80074 9.81701 7.51191 9.56944L1.05446 4.03449ZM13.7697 2.65347C13.5328 2.5546 13.2728 2.5 13 2.5H3C2.60662 2.5 2.23977 2.61357 1.93044 2.80971L7.98153 7.99636L13.7697 2.65347Z',
  menuLine:
    'M1 3C1 2.44772 1.44772 2 2 2H18C18.5523 2 19 2.44772 19 3C19 3.55228 18.5523 4 18 4H2C1.44772 4 1 3.55228 1 3ZM1 10C1 9.44771 1.44772 9 2 9H18C18.5523 9 19 9.44771 19 10C19 10.5523 18.5523 11 18 11H2C1.44772 11 1 10.5523 1 10ZM2 16C1.44772 16 1 16.4477 1 17C1 17.5523 1.44772 18 2 18H18C18.5523 18 19 17.5523 19 17C19 16.4477 18.5523 16 18 16H2Z',
  operatorsInfo:
    'M1.87501 6.74608C1.54818 7.07291 1.54818 7.6028 1.87501 7.92963L4.40507 10.4597C4.7319 10.7865 5.26179 10.7865 5.58862 10.4597C5.63891 10.4094 5.67555 10.3471 5.69508 10.2788L5.88223 9.62375C5.93208 9.44928 6.06846 9.3129 6.24293 9.26305L6.87134 9.0835C6.95716 9.05898 7.03532 9.013 7.09843 8.94989L7.72602 8.32229L12.4623 13.6157C12.9377 14.147 13.762 14.1699 14.2661 13.6657C14.7703 13.1615 14.7474 12.3373 14.216 11.8619L8.92263 7.12568L9.57977 6.46854C9.64288 6.40543 9.68887 6.32728 9.71339 6.24146L9.89293 5.61304C9.94278 5.43857 10.0792 5.30219 10.2536 5.25235L10.9086 5.0652C10.977 5.04566 11.0393 5.00902 11.0896 4.95874C11.4164 4.63191 11.4164 4.10201 11.0896 3.77518L8.55951 1.24512C8.23268 0.918293 7.70279 0.918292 7.37596 1.24512C7.32567 1.29541 7.28903 1.35768 7.2695 1.42605L7.08235 2.08106C7.0325 2.25554 6.89612 2.39191 6.72165 2.44176L6.09324 2.62131C6.00742 2.64583 5.92927 2.69182 5.86615 2.75493L3.38481 5.23627C3.3217 5.29938 3.27571 5.37754 3.25119 5.46335L3.07165 6.09177C3.0218 6.26624 2.88542 6.40262 2.71095 6.45247L2.05594 6.63962C1.98756 6.65915 1.92529 6.69579 1.87501 6.74608ZM1.25 14C1.25 13.5858 1.58579 13.25 2 13.25H7C7.41421 13.25 7.75 13.5858 7.75 14C7.75 14.4142 7.41421 14.75 7 14.75H2C1.58579 14.75 1.25 14.4142 1.25 14Z',
  shipment:
    'M6.83333 3.50002V11.5C6.83333 11.7761 7.05719 12 7.33333 12L9.11121 12C9.23752 10.5984 10.4155 9.5 11.85 9.5C13.2836 9.5 14.461 10.597 14.5886 11.9974C15.3758 11.9516 16 11.2987 16 10.5V3.5C16 2.67157 15.3284 2 14.5 2L8.33333 2.00002C7.5049 2.00002 6.83333 2.67159 6.83333 3.50002ZM11.8833 14.0997C12.9051 14.0997 13.7333 13.2715 13.7333 12.2497C13.7333 11.228 12.9051 10.3997 11.8833 10.3997C10.8616 10.3997 10.0333 11.228 10.0333 12.2497C10.0333 13.2715 10.8616 14.0997 11.8833 14.0997ZM3.32945 4.33333H5.33333C5.60948 4.33333 5.83333 4.55719 5.83333 4.83333L5.83333 10.0013C5.38566 9.68548 4.83949 9.5 4.25 9.5C2.81549 9.5 1.63752 10.5984 1.51121 12H1.5C0.671573 12 0 11.3284 0 10.5V7.77677C0 7.39462 0.145857 7.02687 0.407795 6.74862L2.23725 4.80519C2.5207 4.50407 2.9159 4.33333 3.32945 4.33333ZM4.25 14.0997C5.27172 14.0997 6.1 13.2715 6.1 12.2497C6.1 11.228 5.27172 10.3997 4.25 10.3997C3.22827 10.3997 2.4 11.228 2.4 12.2497C2.4 13.2715 3.22827 14.0997 4.25 14.0997Z',
  shopSetting:
    'M1 11.109V6.176a2 2 0 01.757-1.567l3.689-2.926a2 2 0 012.485 0l3.689 2.926a2 2 0 01.742 1.317 1.737 1.737 0 00-.86-.229h-1.004c-.796 0-1.53.557-1.677 1.367-.748-.283-1.638-.023-2.078.701l-.503.825a1.64 1.64 0 00.342 2.107 1.64 1.64 0 00-.342 2.109l.184.303H3a2 2 0 01-2-2zm10.503 3.588h-1.005c-.388 0-.703-.298-.703-.666v-.293a.608.608 0 00-.323-.53.677.677 0 00-.646 0l-.269.146a.725.725 0 01-.96-.244l-.503-.824a.648.648 0 01.258-.911l.267-.146c.2-.11.323-.312.323-.531a.608.608 0 00-.323-.532l-.267-.145a.648.648 0 01-.257-.911l.502-.825a.725.725 0 01.961-.244l.266.146c.2.11.447.11.647 0s.324-.313.324-.532v-.291c0-.368.315-.667.703-.667h1.005c.388 0 .703.299.703.667v.293c0 .219.123.42.323.53.2.11.447.11.647 0l.266-.146a.725.725 0 01.96.245l.503.824a.648.648 0 01-.257.911l-.268.146a.607.607 0 00-.323.53c0 .22.123.421.323.53l.268.147a.648.648 0 01.258.911l-.503.825a.725.725 0 01-.96.244l-.27-.147a.676.676 0 00-.644 0 .606.606 0 00-.323.53v.294c0 .368-.315.666-.703.666zM11 12.126c.832 0 1.507-.64 1.507-1.429S11.833 9.27 11 9.27c-.833 0-1.507.64-1.507 1.428 0 .79.674 1.429 1.507 1.429z',
};

export default icoD;
