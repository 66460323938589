import PropTypes from 'prop-types';

import { color as colors, COLOR } from 'styles/colors';

export default function IcoDelete(props) {
  const { color } = props;

  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 1.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H6zM1.75 5a.75.75 0 01.75-.75h11a.75.75 0 010 1.5h-.575l-.745 7.449A2 2 0 0110.19 15H5.81a2 2 0 01-1.99-1.801L3.075 5.75H2.5A.75.75 0 011.75 5zm4.396 2.705a.7.7 0 01.739.659l.2 3.5a.7.7 0 11-1.398.08l-.2-3.5a.7.7 0 01.66-.74zm3.142.659a.7.7 0 011.398.08l-.2 3.5a.7.7 0 11-1.398-.08l.2-3.5z'
        fill={color}
      />
    </svg>
  );
}

IcoDelete.propTypes = {
  color: PropTypes.string,
};

IcoDelete.defaultProps = {
  color: colors[COLOR.GRAY][400],
};
