import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { color, COLOR } from 'styles/colors';
import SubModal from 'components/molecules/SubModal';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    transform: translateY(15px);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(15px);
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 0;
  }
`;

const SectionMenu = styled.li`
  border-radius: 6px;
  transition: all 0.2s linear;

  a {
    display: block;
    padding: 8px;
    font-size: 13px;
    line-height: 19px;
  }

  &:hover {
    background-color: ${color[COLOR.GRAY][100]};
  }
`;

const ToHomeWrapper = styled.div`
  padding-bottom: 7px;
  border-bottom: 1px solid ${color[COLOR.GRAY][250]};

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;

    &:hover {
      background-color: ${color[COLOR.GRAY][100]};
    }
  }
`;

const ToHomeTextWrapper = styled.div`
  display: block;

  p {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: -0.5px;
  }

  span {
    color: ${color[COLOR.GRAY][500]};
    font-size: 12px;
    line-height: 15px;
  }
`;

const MenuList = styled.ul`
  padding: 8px 0;
  border-bottom: 1px solid ${color.gray[250]};
  border-bottom: 1px solid ${color[COLOR.GRAY][250]};

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export default function HamburgerMenuModal(props) {
  const { isOpen, onOuterClick } = props;
  const menus = [
    [
      { path: '/features', text: '제품 소개' },
      { path: '/examples', text: '고객 스토리' },
      { path: '/pricing', text: '가격 안내' },
      { path: '/helpCenter', text: '고객센터' },
    ],
    [
      { path: 'https://help.sixshop.com/learn-sixshop/get-started', text: '식스샵 배워보기' },
      { path: 'https://help.sixshop.com/faq', text: '자주 묻는 질문' },
      { path: 'https://help.sixshop.com/news/updates', text: '업데이트 소식' },
      { path: 'https://help.sixshop.com/news/notice', text: '공지사항' },
    ],
  ];

  return (
    <SubModal
      isOpen={isOpen}
      onOuterClick={onOuterClick}
      top='35px'
      left='190px'
      padding='8px'
      fadeInKeyframes={fadeIn}
      fadeOutKeyframes={fadeOut}
    >
      <ToHomeWrapper>
        <a href='/home' target='_blank' rel='noreferrer'>
          <ToHomeTextWrapper>
            <p>sixshop.com</p>
            <span>식스샵 홈페이지 바로 가기</span>
          </ToHomeTextWrapper>
        </a>
      </ToHomeWrapper>
      {menus.map((menuChunk) => (
        <MenuList key={menuChunk[0].path}>
          {menuChunk.map((menu) => (
            <SectionMenu key={menu.path}>
              <a href={`${menu.path}`} target='_blank' rel='noreferrer'>
                {menu.text}
              </a>
            </SectionMenu>
          ))}
        </MenuList>
      ))}
    </SubModal>
  );
}

HamburgerMenuModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOuterClick: PropTypes.func.isRequired,
};
