import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useBeforeunload } from 'react-beforeunload';
import { useQueryClient } from 'react-query';

import requestLogout from 'api/member/requestLogout';
import requestDomainPaymentApproval from 'api/domain/requestDomainPaymentApproval';
import requestFinishDomainRegistration from 'api/domain/requestFinishDomainRegistration';
import { PATH } from 'constants/path';
import { ALERT_MESSAGE } from 'constants/domain';
import { QUERY_KEY } from 'constants/queryKey';
import useMemberNo from 'hooks/useMemberNo';
import { LargePurpleButton } from 'components/atoms/Button';
import Alert from 'components/atoms/Alert';
import HeadlineHeader from 'components/molecules/HeadlineHeader';
import Section from 'components/molecules/Section';
import InputWithTitleOnSide from 'components/molecules/InputWithTitleOnSide';
import BodyWrapperWithSectionDividers from 'components/wrappers/BodyWrapperWithSectionDividers';
import DetailPageLayout from 'layouts/DetailPageLayout';

const MarginBottom = styled.div`
  margin-bottom: 20px;
`;

export default function DomainPaymentResult(props) {
  const { type, onMount } = props;
  const { pathname, search: urlQuery } = useLocation();
  const { orderId, paymentKey, amount, message: failureMessage } = parseQueryString(urlQuery);
  const paths = pathname.split('/');
  const isPaymentSuccess = paths[5] === 'success';
  const history = useHistory();
  const memberNo = useMemberNo();
  const [isProcessDone, setIsProcessDone] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    onMount();
    if (!memberNo) logout();
  }, []);

  async function logout() {
    await requestLogout();
    window.location.assign('/member/login');
  }

  if (!orderId || !paymentKey || !amount) {
    history.push(`${PATH.STORE_SETTING}${PATH.DOMAIN}`);
  }

  // 유틸
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.replace(`${PATH.STORE_SETTING}${PATH.DOMAIN}`);
      }
    };
  }, [history.action]);

  useBeforeunload((event) => {
    if (isPaymentSuccess && !isProcessDone) {
      event.preventDefault();
    }
  });

  function goToDomainMain() {
    if (isPaymentSuccess && !isProcessDone) {
      setAlertMessage('도메인을 등록 중입니다. 잠시만 기다려 주세요.');

      return;
    }

    history.push(`${PATH.STORE_SETTING}${PATH.DOMAIN}`);
  }

  function closeAlert() {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);
  }

  return (
    <DetailPageLayout onBackClick={goToDomainMain}>
      {alertMessage && (
        <Alert
          text={alertMessage}
          top='0'
          fontSize='16px'
          padding='15px'
          closeFunction={closeAlert}
        />
      )}
      {isPaymentSuccess ? (
        <PaymentSuccess
          onConfirmClick={goToDomainMain}
          setAlertMessage={setAlertMessage}
          type={type}
          orderId={orderId}
          paymentKey={paymentKey}
          amount={amount}
          isDone={isProcessDone}
          setIsDone={setIsProcessDone}
        />
      ) : (
        <PaymentFail
          onConfirmClick={goToDomainMain}
          setAlertMessage={setAlertMessage}
          message={failureMessage}
        />
      )}
    </DetailPageLayout>
  );
}

DomainPaymentResult.propTypes = {
  type: PropTypes.string.isRequired,
  onMount: PropTypes.func,
};

DomainPaymentResult.defaultProps = {
  onMount: () => {},
};

function PaymentSuccess(props) {
  const { onConfirmClick, setAlertMessage, type, orderId, paymentKey, amount, isDone, setIsDone } =
    props;
  const processingText = '결제 승인 요청 및 도메인 등록 중...';
  const [hasSucceededDomainRegistration, setHasSucceededDomainRegistration] = useState(false);
  const [title, setTitle] = useState(processingText);
  const [domain, setDomain] = useState('');
  const [period, setPeriod] = useState('');
  const [payedAmount, setPayedAmount] = useState(0);
  const queryClient = useQueryClient();

  useEffect(() => {
    requestDomainOrderProcess();

    async function requestDomainOrderProcess() {
      const paymentSuccessRes = await requestDomainPaymentApproval({
        orderId,
        amount,
        tid: paymentKey,
      });

      setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);

      if (paymentSuccessRes.status !== 204) {
        setIsDone(true);
        setAlertMessage(
          '결제 승인이 실패했습니다. 카드 정보를 확인하거나 다른 카드로 시도해 주세요.'
        );
      }

      let doneRes;

      try {
        doneRes = await requestFinishDomainRegistration(type, orderId);
      } catch (err) {
        setAlertMessage(err.message);
        setPayedAmount(Number(amount));
        setIsDone(true);

        return;
      }

      setIsDone(true);

      if (doneRes.status === 'CANCEL') {
        setAlertMessage(doneRes.message || '도메인 등록 실패로 결제를 취소했습니다.');

        return;
      }

      queryClient.invalidateQueries(QUERY_KEY.DOMAINS);
      setHasSucceededDomainRegistration(true);
      setDomain(doneRes.address);
      setPeriod(doneRes.period);
      setPayedAmount(Number(doneRes.price));
    }
  }, []);

  useEffect(() => {
    if (!isDone) {
      setTitle(processingText);

      return;
    }

    hasSucceededDomainRegistration ? setTitle('구매 완료') : setTitle('구매 실패');
  }, [isDone, hasSucceededDomainRegistration]);

  return (
    <>
      <Helmet>
        <title>Sixshop | {title}</title>
      </Helmet>
      <HeadlineHeader title={title} />
      {isDone && (
        <BodyWrapperWithSectionDividers>
          <Section>
            <MarginBottom>
              <InputWithTitleOnSide
                title='도메인'
                inputValue={hasSucceededDomainRegistration ? `${decodeURI(`www.${domain}`)}` : '-'}
                isInputDisabled
              />
            </MarginBottom>
            <MarginBottom>
              <InputWithTitleOnSide
                title='이용기간'
                inputValue={hasSucceededDomainRegistration ? `${period}년` : '-'}
                isInputDisabled
              />
            </MarginBottom>
            <InputWithTitleOnSide
              title='결제금액'
              inputValue={`${payedAmount.toLocaleString()}원`}
              isInputDisabled
            />
          </Section>
          <Section>
            <LargePurpleButton onClick={onConfirmClick}>확인</LargePurpleButton>
          </Section>
        </BodyWrapperWithSectionDividers>
      )}
    </>
  );
}

PaymentSuccess.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  paymentKey: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  isDone: PropTypes.bool.isRequired,
  setIsDone: PropTypes.func.isRequired,
};

function PaymentFail(props) {
  const { onConfirmClick, setAlertMessage, message } = props;

  useEffect(() => {
    setAlertMessage(message);
  }, []);

  function goToDomainMain() {
    onConfirmClick();
  }

  return (
    <>
      <Helmet>
        <title>Sixshop | 결제 실패</title>
      </Helmet>
      <HeadlineHeader title='결제 실패' />
      <BodyWrapperWithSectionDividers>
        <Section>
          <LargePurpleButton onClick={goToDomainMain}>확인</LargePurpleButton>
        </Section>
      </BodyWrapperWithSectionDividers>
    </>
  );
}

PaymentFail.propTypes = {
  onConfirmClick: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
};

PaymentFail.defaultProps = {
  message: ALERT_MESSAGE.PAYMENT_FAIL,
};

function parseQueryString(queryString) {
  return queryString
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      acc[key] = value;

      return acc;
    }, {});
}
