import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';

const Wrapper = styled(PointerOnHoverWrapper)`
  margin: 2px 0;
  padding: 8px 8px 8px 20px;
  background-color: ${({ isCurrentSection }) => isCurrentSection && color.purple[100]};
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${({ isCurrentSection }) => !isCurrentSection && color[COLOR.GRAY][100]};
  }
`;

const Text = styled.span`
  flex: 1;
  padding-left: 12px;
  overflow: hidden;
  color: ${({ isCurrentSection }) => (isCurrentSection ? color.purple[500] : color.black)};
  font-weight: ${({ isCurrentSection }) => (isCurrentSection ? 600 : 400)};
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export default function NavTab({ icon, isCurrentSection, children }) {
  return (
    <Wrapper isCurrentSection={isCurrentSection}>
      {icon}
      <Text isCurrentSection={isCurrentSection}>{children}</Text>
    </Wrapper>
  );
}

NavTab.propTypes = {
  icon: PropTypes.element.isRequired,
  isCurrentSection: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};
