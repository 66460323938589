export default function IcoDomainLink() {
  return (
    <svg width={50} height={50} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx={24.375} cy={24.375} r={17.625} stroke='#222' strokeWidth={1.5} />
      <path
        d='M33.425 24.375c0 4.976-1.077 9.444-2.782 12.64C28.92 40.244 26.669 42 24.375 42c-2.294 0-4.546-1.756-6.268-4.985-1.705-3.196-2.782-7.664-2.782-12.64 0-4.976 1.077-9.444 2.782-12.64 1.722-3.229 3.974-4.985 6.268-4.985 2.294 0 4.546 1.756 6.268 4.985 1.705 3.196 2.782 7.664 2.782 12.64z'
        stroke='#222'
        strokeWidth={1.5}
      />
      <path
        d='M8.625 19.125h31.5M8.625 29.625h31.5'
        stroke='#222'
        strokeWidth={1.5}
        strokeLinecap='round'
      />
      <path d='M24.375 6v36.75' stroke='#222' strokeWidth={1.5} />
      <circle cx={35.5} cy={36.5} r={8.75} fill='#fff' stroke='#000' strokeWidth={1.5} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.029 33.4a1.392 1.392 0 010 1.97l-1.693 1.692a1.392 1.392 0 01-1.969 0 .75.75 0 00-1.06 1.06 2.892 2.892 0 004.09 0l1.692-1.692A2.892 2.892 0 0036 32.34a.75.75 0 101.06 1.06 1.392 1.392 0 011.97 0zm-7.009 6.197a1.392 1.392 0 010-1.969l1.693-1.693a1.392 1.392 0 011.969 0 .75.75 0 001.06-1.06 2.892 2.892 0 00-4.09 0l-1.693 1.693a2.892 2.892 0 104.09 4.09.75.75 0 00-1.06-1.06 1.392 1.392 0 01-1.969 0z'
        fill='#222'
      />
    </svg>
  );
}
