import { useState, useEffect } from 'react';

export default function useAnimation(isActive) {
  const [shouldRender, setShouldRender] = useState(isActive);

  useEffect(() => {
    if (!isActive) return;

    setShouldRender(true);
  });

  function unmount() {
    if (isActive) return;

    setShouldRender(false);
  }

  return [shouldRender, unmount];
}
