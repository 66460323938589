import PropTypes from 'prop-types';

import { color as colors } from 'styles/colors';

export default function NavIco(props) {
  const { width, height, d, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fillRule='evenodd' clipRule='evenodd' d={d} fill={color} />
    </svg>
  );
}

NavIco.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  d: PropTypes.string.isRequired,
  color: PropTypes.string,
};

NavIco.defaultProps = {
  width: 16,
  height: 16,
  color: colors.black,
};
