import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

const ScrollThumb = styled.div`
  width: 7px;
  height: 7px;
  background-color: #000;
  border-radius: 8px;
  opacity: ${({ isActive }) => (isActive ? 0.4 : 0)};

  &:hover {
    background-color: #222;
  }
`;

function renderThumb(isActive) {
  return <ScrollThumb isActive={isActive} />;
}

export default function Scrollbar({ children, isActive, ...props }) {
  return (
    <Scrollbars
      renderThumbHorizontal={() => renderThumb(isActive)}
      renderThumbVertical={() => renderThumb(isActive)}
      {...props}
    >
      {children}
    </Scrollbars>
  );
}

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
};

Scrollbar.defaultProps = {
  isActive: true,
};
