import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalStyle } from 'styles/globalStyle';
import App from 'App';

if (process.env.NODE_ENV === 'development') {
  window.localStorage.setItem(
    'authentication',
    JSON.stringify({
      email: 'seunghyeun@sixshop.com',
      id: 30562,
      username: 'addontest',
      token_type: 'bearer',
      expire_in: 2592000,
      access_token:
        'eyJyZWZyZXNoYWJsZSI6ZmFsc2UsInR5cCI6IkpXVCIsImFsZyI6IkhTNTEyIn0.eyJtZW1iZXJObyI6MzA1NjIsInJvbGVzIjpbIkNSRUFURV9ERVNJR04iLCJSRUFEX0RFU0lHTiIsIlVQREFURV9ERVNJR04iLCJERUxFVEVfREVTSUdOIiwiQ1JFQVRFX1BST0RVQ1QiLCJSRUFEX1BST0RVQ1QiLCJVUERBVEVfUFJPRFVDVCIsIkRFTEVURV9QUk9EVUNUIiwiUkVBRF9TVEFUSVNUSUNTIiwiTUFOQUdFUiJdLCJpc3MiOiJzaXhzaG9wMiIsImV4cCI6MTY1MTg4Mzg3MSwiaWF0IjoxNjQ5MjkxODcxLCJlbWFpbCI6InNldW5naHlldW5Ac2l4c2hvcC5jb20iLCJqdGkiOiI1MWM2ZWFkNS0zODJiLTRiMzQtYTVhYi1mNmY1ZjcxZmMxZWEiLCJ1c2VybmFtZSI6ImFkZG9udGVzdCJ9.nD4cQu7UUXGeCBiuO4mxbAb0C_YRPUi9jrVDfYjgWA_ZRF_npL7lVlJk8M4H3PdPWnU_UjK2TBRI25osTFY1ZA',
      refresh_token:
        'eyJyZWZyZXNoYWJsZSI6dHJ1ZSwidHlwIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJtZW1iZXJObyI6MzA1NjIsInJvbGVzIjpbIkNSRUFURV9ERVNJR04iLCJSRUFEX0RFU0lHTiIsIlVQREFURV9ERVNJR04iLCJERUxFVEVfREVTSUdOIiwiQ1JFQVRFX1BST0RVQ1QiLCJSRUFEX1BST0RVQ1QiLCJVUERBVEVfUFJPRFVDVCIsIkRFTEVURV9QUk9EVUNUIiwiUkVBRF9TVEFUSVNUSUNTIiwiTUFOQUdFUiJdLCJpc3MiOiJzaXhzaG9wMiIsImV4cCI6MTg4MjU3MTg3MSwiaWF0IjoxNjQ5MjkxODcxLCJqdGkiOiI0MGY2ZTFhMS1lNmU0LTQzNjAtYWI2OC0zNDNlZTJmNTkzYTciLCJlbWFpbCI6InNldW5naHlldW5Ac2l4c2hvcC5jb20iLCJ1c2VybmFtZSI6ImFkZG9udGVzdCJ9.qm9TjGCWSNbtktIsFdhhtu9xSJpncAegqku6micblZA38OaeSjXphCa2Jac26aKsqsH-qmxdRdcmTnfDLCA0AQ',
    })
  );
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <GlobalStyle />
          <App />
        </Router>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
