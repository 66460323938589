import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import FlexBox from 'components/wrappers/FlexBox';
import SectionPath from 'components/molecules/SectionPath';

const PathWrapper = styled(FlexBox)`
  margin-bottom: 16px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 13px;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  text-align: left;
`;

const TitleWrapper = styled.div`
  display: inline-block;
  max-width: 285px;
  margin-top: 9px;
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

export default function MainHeader(props) {
  const { sectionPath, title, children } = props;

  return (
    <>
      <PathWrapper>
        <SectionPath sectionPath={sectionPath} />
      </PathWrapper>
      <MainWrapper>
        <TitleWrapper>{title}</TitleWrapper>
        {children}
      </MainWrapper>
    </>
  );
}

MainHeader.propTypes = {
  sectionPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
};

MainHeader.defaultProps = {
  children: null,
};
