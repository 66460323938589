import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color as colors } from '../styles/colors';

const AnimatedPath = styled.path`
  transition: 0.3s;
`;

export default function IcoCloseLine(props) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <AnimatedPath
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.53 3.53a.75.75 0 00-1.06-1.06L8 6.94 3.53 2.47a.75.75 0 00-1.06 1.06L6.94 8l-4.47 4.47a.75.75 0 101.06 1.06L8 9.06l4.47 4.47a.75.75 0 101.06-1.06L9.06 8l4.47-4.47z'
        fill={color}
      />
    </svg>
  );
}

IcoCloseLine.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

IcoCloseLine.defaultProps = {
  width: 18,
  height: 18,
  color: colors.black,
};
