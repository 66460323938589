import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';
import IcoQuestionCircleLine from 'icons/IcoQuestionCircleLine';
import IcoCloseLineBig from 'icons/IcoCloseLineBig';
import Alert from 'components/atoms/Alert';
import Input from 'components/atoms/Input';
import MainModal from 'components/molecules/MainModal';
import DnsRecordSettings from 'components/organisms/DnsRecordSettings';
import FlexBox from 'components/wrappers/FlexBox';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';

const Header = styled(FlexBox)`
  position: relative;
  justify-content: space-between;
  padding: 26px 30px;
  border-bottom: 1px solid ${color[COLOR.GRAY][200]};

  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  max-height: calc(95vh - 192px);
  padding: 24px 30px 0;
  overflow: auto;
`;

const Content = styled.div`
  margin-bottom: 26px;
`;

const ContentHeader = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 16px;

  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5em;
  }

  a {
    display: flex;
    align-items: center;
    color: ${color[COLOR.PURPLE][500]};
    font-size: 14px;
    text-decoration: none;
  }
`;

const ContentBody = styled.div`
  width: 100%;
  padding: 15px 14px 0;
  font-size: 14px;
  border: 1px solid ${color[COLOR.GRAY][300]};
  border-radius: 10px;
`;

const NsDataText = styled.div`
  margin-bottom: 15px;
  padding: 0 5px;
  color: ${color[COLOR.GRAY][500]};
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
`;

const NsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.33fr 9fr;
  margin-bottom: 15px;
  padding: 0 5px;
  font-size: 13px;
`;

const SubText = styled.span`
  color: ${color[COLOR.GRAY][500]};
  font-size: 10px;
`;

const Bold = styled.span`
  font-weight: 600;
`;

export default function DomainDetailsModal(props) {
  const { domainInfo, isOpen, setIsOpen } = props;
  const [alertMessage, setAlertMessage] = useState('');
  const nameServers = domainInfo.nameServers
    .replace('[', '')
    .replace(']', '')
    .replaceAll(' ', '')
    .split(',');
  const ordinalNumbers = { 1: '1st', 2: '2nd', 3: '3rd', 4: '4th' };

  function closeModal() {
    setIsOpen(null);
  }

  function handleCloseAlert() {
    setAlertMessage('');
  }

  function handleNameServerHostClick(event) {
    navigator.clipboard.writeText(event.target.getAttribute('value'));
  }

  return (
    <MainModal toClose={closeModal} isOpen={isOpen}>
      <Header>
        <h4>도메인 설정하기</h4>
        <PointerOnHoverWrapper onClick={closeModal}>
          <IcoCloseLineBig width={20} height={20} color={color[COLOR.BLACK]} />
        </PointerOnHoverWrapper>
      </Header>
      {alertMessage && (
        <Alert
          text={alertMessage}
          top='79px'
          fontSize='15px'
          padding='15px 30px'
          closeFunction={handleCloseAlert}
        />
      )}
      <ContentWrapper>
        <Content>
          <ContentHeader>
            <h6>도메인 주소</h6>
          </ContentHeader>
          <Input value={domainInfo.domainAddress} disabled $width='100%' />
        </Content>
        <Content>
          <ContentHeader>
            <h6>DNS 레코드 관리</h6>
            <a href='https://help.sixshop.com/learn-sixshop/get-started/prepare-to-sell/domains#4-dns'>
              <IcoQuestionCircleLine color={color[COLOR.PURPLE][500]} />
              &nbsp; DNS 레코드 설정 방법 보기
            </a>
          </ContentHeader>
          <ContentBody>
            <DnsRecordSettings
              domainNo={domainInfo.domainNo}
              domainAddress={domainInfo.domainAddress}
              dnsRecords={domainInfo.resourceRecordSetList}
              setAlertMessage={setAlertMessage}
            />
          </ContentBody>
        </Content>
        <Content>
          <ContentHeader>
            <h6>네임서버 정보</h6>
            <a href='https://help.sixshop.com/learn-sixshop/get-started/prepare-to-sell/domains'>
              <IcoQuestionCircleLine color={color[COLOR.PURPLE][500]} />
              &nbsp; 네임서버 설정 방법 보기
            </a>
          </ContentHeader>
          <ContentBody>
            <NsDataText>
              도메인 공급업체의 웹사이트에 접속하여 네임서버 설정을 변경해주세요.
              <br />
              도메인이 완전히 연결되려면 48시간이 걸립니다.
            </NsDataText>
            <NsRow>
              <span>구분</span>
              <span>IP주소</span>
              <span>
                호스트명&nbsp;<SubText>호스트명 클릭 시 클립보드로 복사됩니다.</SubText>
              </span>
            </NsRow>
            {nameServers.map((nameServer, index) => (
              <NsRow key={nameServer}>
                <Bold>{ordinalNumbers[index + 1]}</Bold>
                <span>{domainInfo.nameServerIps[index]}</span>
                <PointerOnHoverWrapper value={nameServer} onClick={handleNameServerHostClick}>
                  {nameServer}
                </PointerOnHoverWrapper>
              </NsRow>
            ))}
          </ContentBody>
        </Content>
      </ContentWrapper>
    </MainModal>
  );
}

DomainDetailsModal.propTypes = {
  domainInfo: PropTypes.shape({
    domainAddress: PropTypes.string.isRequired,
    domainNo: PropTypes.number.isRequired,
    expirationDate: PropTypes.instanceOf(Date),
    isMain: PropTypes.number.isRequired,
    memberNo: PropTypes.number.isRequired,
    nameServerIps: PropTypes.arrayOf(PropTypes.string).isRequired,
    nameServers: PropTypes.string.isRequired,
    resourceRecordSetList: PropTypes.arrayOf(PropTypes.object).isRequired,
    purchasedDate: PropTypes.instanceOf(Date),
    wasPurchasedFromSixshop: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
