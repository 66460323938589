import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { color, COLOR } from 'styles/colors';
import useAnimation from 'hooks/useAnimation';
import useOuterClick from 'hooks/useOuterClick';
import Modal from 'components/atoms/Modal';

const Container = styled.div`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  width: 240px;
  border: 1px solid ${color[COLOR.GRAY][300]};
  border-radius: 10px;
  box-shadow: 10px 10px 50px rgb(0 0 0 / 5%);
  padding: ${({ padding }) => padding};
  background-color: ${color[COLOR.WHITE]};
  z-index: 1;
  animation: ${css`
    ${({ isActive, fadeInKeyframes, fadeOutKeyframes }) =>
      isActive ? fadeInKeyframes : fadeOutKeyframes} 200ms linear forwards;
  `};
`;

export default function SubModal(props) {
  const {
    isOpen,
    onOuterClick,
    top,
    bottom,
    left,
    padding,
    fadeInKeyframes,
    fadeOutKeyframes,
    children,
  } = props;
  const [shouldRender, unmount] = useAnimation(isOpen);
  const selfRef = useOuterClick(onOuterClick);

  return shouldRender ? (
    <Container
      isActive={isOpen}
      ref={selfRef}
      top={top}
      bottom={bottom}
      left={left}
      padding={padding}
      fadeInKeyframes={fadeInKeyframes}
      fadeOutKeyframes={fadeOutKeyframes}
      onAnimationEnd={unmount}
    >
      <Modal>{children}</Modal>
    </Container>
  ) : null;
}

SubModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOuterClick: PropTypes.func.isRequired,
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  padding: PropTypes.string,
  fadeInKeyframes: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    rules: PropTypes.string.isRequired,
    inject: PropTypes.func.isRequired,
    toString: PropTypes.func.isRequired,
  }).isRequired,
  fadeOutKeyframes: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    rules: PropTypes.string.isRequired,
    inject: PropTypes.func.isRequired,
    toString: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

SubModal.defaultProps = {
  top: '',
  bottom: '',
  left: '',
  padding: '',
};
