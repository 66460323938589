import { loadTossPayments } from '@tosspayments/sdk';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { LargePurpleButton } from 'components/atoms/Button';

export default function TossPayments(props) {
  const { preRequest, fetchPaymentData, successUrl, failUrl } = props;
  const [tossPayments, setTossPayments] = useState(null);

  useEffect(() => {
    initiateTossPayments();

    async function initiateTossPayments() {
      const tossPaymentsClientKey = process.env.REACT_APP_TOSS_PAYMENTS_KEY;
      const tossPaymentsInstance = await loadTossPayments(tossPaymentsClientKey);
      setTossPayments(tossPaymentsInstance);
    }
  }, []);

  async function handlePayClick() {
    if (preRequest) {
      const preRequestRes = preRequest();

      if (!preRequestRes) return;
    }

    const paymentData = await fetchPaymentData();
    const { id, title, orderer, price } = paymentData;

    tossPayments.requestPayment('카드', {
      amount: price,
      orderId: id,
      orderName: title,
      customerName: orderer,
      successUrl,
      failUrl,
    });
  }

  return <LargePurpleButton onClick={handlePayClick}>결제하기</LargePurpleButton>;
}

TossPayments.propTypes = {
  preRequest: PropTypes.func,
  fetchPaymentData: PropTypes.func.isRequired,
  successUrl: PropTypes.string.isRequired,
  failUrl: PropTypes.string.isRequired,
};

TossPayments.defaultProps = {
  preRequest: undefined,
};
