import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import FlexBox from 'components/wrappers/FlexBox';

const TableHeader = styled(FlexBox)`
  height: 50px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 14px;
  border-top: 1px solid ${color[COLOR.GRAY][200]};
`;

const StyledTh = styled.div`
  display: inline-flex;
  flex: ${({ flex }) => flex};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const TableBody = styled.ul`
  > :nth-child(n) {
    border-bottom: 1px solid ${color[COLOR.GRAY][200]};
  }

  > :first-child {
    border-top: 1px solid ${color[COLOR.GRAY][200]};
  }
`;

export default function Table(props) {
  const { hasHeader, columnsInfo, children } = props;

  return (
    <>
      {hasHeader && (
        <TableHeader>
          {columnsInfo.map((columnInfo) => (
            <StyledTh
              key={columnInfo.title}
              flex={columnInfo.flex}
              justifyContent={columnInfo.alignment}
            >
              {columnInfo.title}
            </StyledTh>
          ))}
        </TableHeader>
      )}
      <TableBody>{children}</TableBody>
    </>
  );
}

Table.propTypes = {
  hasHeader: PropTypes.bool.isRequired,
  columnsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      flex: PropTypes.number,
    })
  ),
  children: PropTypes.node.isRequired,
};

Table.defaultProps = {
  columnsInfo: [],
};
