import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { color, COLOR } from 'styles/colors';
import requestSwitchAccount from 'api/member/requestSwitchAccount';
import requestLogout from 'api/member/requestLogout';
import { PLAN } from 'constants/plan';
import { RESPONSE_RESULT } from 'constants/responseResult';
import IcoCheckLine from 'icons/IcoCheckLine';
import IcoMasterPurple from 'icons/IcoMasterPurple';
import Scrollbar from 'components/atoms/Scrollbar';
import SubModal from 'components/molecules/SubModal';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import { ThumbnailWrapper, ThumbnailPlaceholder } from 'components/wrappers/MemberInfoThumbnail';
import FlexBox from 'components/wrappers/FlexBox';
import ChevronRight from 'icons/ChevronRight';
import useMemberNo from 'hooks/useMemberNo';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    transform: translateY(-15px);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(-15px);
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 0;
  }
`;

const MemberInfoWrapper = styled(FlexBox)`
  margin: 0 8px;
  padding: 14px 8px;
  border-bottom: 1px solid ${color[COLOR.GRAY][250]};
`;

const MemberDetails = styled.div`
  flex: 1;
  margin: 0 6px;
`;

const MemberId = styled.span`
  display: flex;
  align-items: center;
  font-weight: 600px;
  font-size: 14px;
  line-height: 16px;

  .master-icon {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
  }
`;

const MemberPlan = styled.span`
  display: block;
  margin-top: 1px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 10px;
  line-height: 15px;
`;

const ConnectedSitesWrapper = styled.div`
  height: ${({ count }) => `calc(49px + ${count * 48}px)`};
  max-height: 390px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${color[COLOR.GRAY][250]};
`;

const ConnectedSitesTitle = styled.div`
  margin: 10px 0;
  padding: 0 16px;
  color: ${color[COLOR.GRAY][400]};
  font-weight: 600;
  font-size: 12px;
`;

const ConnectedSiteWrapper = styled(PointerOnHoverWrapper)`
  position: relative;
  justify-content: space-between;
  margin: 0 8px;
  padding: 8px;
  border-radius: 10px;
  transition: all 200ms linear;

  &:hover {
    background-color: ${color[COLOR.GRAY][100]};
  }
`;

const TotalCount = styled.div`
  min-width: 32px;
  padding: 2px;
  color: ${color[COLOR.GRAY][400]};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background-color: ${color[COLOR.GRAY][200]};
  border-radius: 10px;
`;

const MenuList = styled.ul`
  margin: 8px 0;
`;

const MemberInfoMenu = styled.li`
  margin: 0 8px;
  padding: 8px;
  font-size: 13px;
  line-height: 19px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${color[COLOR.GRAY][100]};
  }
`;

const ConnectedSiteFront = styled(PointerOnHoverWrapper)`
  flex: 1;
  opacity: 1;
  transition: all 200ms linear;

  &:hover {
    opacity: 0;
  }
`;

export default function MemberInfoModal(props) {
  const { isOpen, onOuterClick, connectedSites, memberInfo } = props;
  const memberNo = useMemberNo();

  async function handleLogout() {
    await requestLogout();
    window.location.assign('/');
  }

  return (
    <SubModal
      isOpen={isOpen}
      onOuterClick={onOuterClick}
      bottom='30px'
      left='15px'
      fadeInKeyframes={fadeIn}
      fadeOutKeyframes={fadeOut}
    >
      <MemberInfoWrapper>
        {memberInfo?.favicon ? (
          <ThumbnailWrapper
            style={{
              backgroundImage: `url(${process.env.REACT_APP_CONTENTS_STORAGE}${memberInfo.favicon})`,
              backgroundSize: 'cover',
            }}
          />
        ) : (
          <ThumbnailWrapper>
            <ThumbnailPlaceholder>{memberInfo?.userName[0]}</ThumbnailPlaceholder>
          </ThumbnailWrapper>
        )}
        <MemberDetails>
          <MemberId>{memberInfo?.userName}</MemberId>
          <MemberPlan>{PLAN[memberInfo?.plan]}</MemberPlan>
        </MemberDetails>
        <IcoCheckLine color={color[COLOR.PURPLE][500]} />
      </MemberInfoWrapper>
      {connectedSites.length > 1 && (
        <ConnectedSitesWrapper count={connectedSites.length}>
          <Scrollbar>
            <ConnectedSitesTitle>연결된 계정</ConnectedSitesTitle>
            {connectedSites.map((siteInfo) => {
              if (siteInfo.childMemberNo === Number(memberNo)) {
                return null;
              }

              const isMaster = siteInfo.memberNo === siteInfo.childMemberNo;

              return (
                <ConnectedSite
                  key={siteInfo.childMemberNo}
                  siteInfo={siteInfo}
                  isMaster={isMaster}
                />
              );
            })}
          </Scrollbar>
        </ConnectedSitesWrapper>
      )}
      <MenuList>
        <a href='/mymenu/account'>
          <MemberInfoMenu>계정 설정</MemberInfoMenu>
        </a>
        {(memberInfo?.plan === 'trial' || memberInfo?.plan === 'superTrial') && (
          <a href='/mymenu/plans'>
            <MemberInfoMenu>업그레이드</MemberInfoMenu>
          </a>
        )}
        <a href='/mymenu/billing'>
          <MemberInfoMenu>결제 정보</MemberInfoMenu>
        </a>
        <MemberInfoMenu onClick={handleLogout}>로그아웃</MemberInfoMenu>
      </MenuList>
    </SubModal>
  );
}

MemberInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  connectedSites: PropTypes.arrayOf(PropTypes.object),
  memberInfo: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    memberNo: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
    favicon: PropTypes.string,
  }),
  onOuterClick: PropTypes.func.isRequired,
};

MemberInfoModal.defaultProps = {
  connectedSites: [],
  memberInfo: null,
};

function ConnectedSite(props) {
  const { siteInfo, isMaster } = props;

  async function handleConnectedSiteClick() {
    const response = await requestSwitchAccount(siteInfo.childMemberNo);

    if (response.RESULT !== RESPONSE_RESULT.OK) return;

    localStorage.setItem('authentication', JSON.stringify(response.authentication));

    window.location.assign('/');
  }

  return (
    <ConnectedSiteWrapper onClick={handleConnectedSiteClick}>
      {siteInfo?.favicon ? (
        <ThumbnailWrapper
          style={{
            backgroundImage: `url(${process.env.REACT_APP_CONTENTS_STORAGE}${siteInfo.favicon})`,
            backgroundSize: 'cover',
          }}
        />
      ) : (
        <ThumbnailWrapper>
          <ThumbnailPlaceholder>{siteInfo?.userName[0]}</ThumbnailPlaceholder>
        </ThumbnailWrapper>
      )}
      <ConnectedSiteFront>
        <MemberDetails>
          <MemberId>
            {isMaster && (
              <span className='master-icon'>
                <IcoMasterPurple />
              </span>
            )}
            {siteInfo.userName}
          </MemberId>
          <MemberPlan>{PLAN[siteInfo?.plan]}</MemberPlan>
        </MemberDetails>
        <TotalCount>{siteInfo.userHomeAlert.totalNewCount}</TotalCount>
      </ConnectedSiteFront>
      <ConnectedSiteBack siteInfo={siteInfo} />
    </ConnectedSiteWrapper>
  );
}

ConnectedSite.propTypes = {
  siteInfo: PropTypes.shape({
    userName: PropTypes.string,
    childMemberNo: PropTypes.number,
    favicon: PropTypes.string,
    plan: PropTypes.string,
    userHomeAlert: PropTypes.shape({
      totalNewCount: PropTypes.number,
    }),
  }).isRequired,
  isMaster: PropTypes.bool.isRequired,
};

const BackWrapper = styled(PointerOnHoverWrapper)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  padding: 8px;
  background-color: ${color[COLOR.GRAY][100]};
  border-radius: 10px;
  opacity: 0;
  transition: all 200ms linear;

  &:hover {
    opacity: 1;
  }
`;

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.span`
  color: #b9b9c5;
  font-weight: 600;
  font-size: 10px;
`;

const Count = styled.span`
  color: #8b8b9e;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
`;

function ConnectedSiteBack(props) {
  const { siteInfo } = props;

  const properties = [
    { title: '주문', count: siteInfo.userHomeAlert.newOrderCount },
    { title: '게시판', count: siteInfo.userHomeAlert.newPostCount },
    { title: '후기와 질문', count: siteInfo.userHomeAlert.newPostProductCount },
  ];

  return (
    <BackWrapper>
      {siteInfo?.favicon ? (
        <ThumbnailWrapper
          style={{
            backgroundImage: `url(${process.env.REACT_APP_CONTENTS_STORAGE}${siteInfo.favicon})`,
            backgroundSize: 'cover',
          }}
        />
      ) : (
        <ThumbnailWrapper>
          <ThumbnailPlaceholder>{siteInfo.userName[0]}</ThumbnailPlaceholder>
        </ThumbnailWrapper>
      )}
      {properties.map((item) => (
        <PropertyWrapper key={item.title}>
          <Title>{item.title}</Title>
          <Count>{item.count}</Count>
        </PropertyWrapper>
      ))}
      <ChevronRight />
    </BackWrapper>
  );
}

ConnectedSiteBack.propTypes = {
  siteInfo: PropTypes.shape({
    favicon: PropTypes.string,
    userName: PropTypes.string,
    userHomeAlert: PropTypes.shape({
      newOrderCount: PropTypes.number,
      newPostCount: PropTypes.number,
      newPostProductCount: PropTypes.number,
    }),
  }).isRequired,
};
