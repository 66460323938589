import { status, message } from 'constants/error';
import { METHOD } from 'constants/method';

export default async function requestSearchDomains(input) {
  const res = await fetch(`${process.env.REACT_APP_SIXSHOP2_USERHOME_SERVER_HOST}/domains/check`, {
    method: METHOD.POST,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      domainName: input,
    }),
  });

  const response = await res.json();

  if (response.status === status.FAIL) {
    throw new Error(response.message);
  }

  if (!res.ok) {
    throw new Error(message.INTERNAL_SERVER_ERROR);
  }

  return response;
}
