import styled from 'styled-components';

const EntireContentWrapper = styled.div`
  width: 1180px;
  min-width: 720px;
  margin: 30px auto 60px;
  padding: 0 40px;
`;

export default EntireContentWrapper;
