import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

import { color, COLOR } from 'styles/colors';
import IcoCheckboxOn from 'icons/IcoCheckboxOn';
import FlexBox from 'components/wrappers/FlexBox';

const fillIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const AnimatedIcoCheckboxOn = styled(IcoCheckboxOn)`
  animation: ${css`
    ${fillIn} 200ms;
  `};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const IndicatorWrapper = styled(FlexBox)`
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: ${({ checked }) => (checked ? null : '1px solid rgba(0, 0, 0, 0.1)')};
  border-radius: 2px;
  background-color: ${({ checked }) => (checked ? color[COLOR.PURPLE][500] : color[COLOR.WHITE])};
`;

const TextWrapper = styled.span`
  font-size: 14px;
  padding-bottom: 1px;

  a {
    color: ${color[COLOR.PURPLE][500]};

    &:hover {
      color: ${color[COLOR.PURPLE][600]};
    }
  }
`;

export default function Checkbox(props) {
  const { value, isChecked, onChange, name, isDisabled, children } = props;

  return (
    <Label disabled={isDisabled}>
      <Input
        type='checkbox'
        name={name}
        value={value}
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
      />
      <IndicatorWrapper checked={isChecked}>
        {isChecked && <AnimatedIcoCheckboxOn isActive={isChecked} />}
      </IndicatorWrapper>
      <TextWrapper>{children}</TextWrapper>
    </Label>
  );
}

Checkbox.propTypes = {
  value: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Checkbox.defaultProps = {
  value: '',
  name: '',
  isDisabled: false,
};
