import PropTypes from 'prop-types';

import { color as colors } from 'styles/colors';

export default function IcoSearchLine(props) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={16}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g mask='url(#prefix__a)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.507 3.672A4 4 0 113.85 9.328a4 4 0 015.657-5.656zm1.432 6.306a5.5 5.5 0 10-1.098 1.023l.029.03 3.75 3.75a.75.75 0 001.061-1.06l-3.742-3.743z'
          fill={color}
        />
      </g>
    </svg>
  );
}

IcoSearchLine.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

IcoSearchLine.defaultProps = {
  width: 16,
  height: 16,
  color: colors.black,
};
