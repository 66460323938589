import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import { PATH } from 'constants/path';
import IcoQuestionCircleLine from 'icons/IcoQuestionCircleLine';
import { PurpleButton, GrayButton } from 'components/atoms/Button';
import MainHeader from 'components/molecules/MainHeader';
import ContentWrapper from 'components/wrappers/FlexBox';

const DescriptionWrapper = styled.div`
  display: inline-block;
  height: 50px;
  line-height: 50px;

  a {
    display: flex;
    align-items: center;
    color: ${color[COLOR.PURPLE][500]};
    font-size: 14px;
    text-decoration: none;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-left: 10px;
`;

export default function DomainMainHeader(props) {
  const { domains, onPurchaseButtonClick, onConnectButtonClick, isTrial } = props;
  const shouldShowButtons = !isTrial && domains?.length > 0;

  function handlePurchaseButtonClick() {
    onPurchaseButtonClick();
  }

  function handleConnectButtonClick() {
    onConnectButtonClick();
  }

  return (
    <MainHeader sectionPath={['상점 설정', '도메인']} title='도메인'>
      <ContentWrapper>
        <DescriptionWrapper>
          <a
            href={`${PATH.HELP}${PATH.LEARN_SIXSHOP}${PATH.GET_STARTED}${PATH.PREPARE_TO_SELL}${PATH.DOMAINS}`}
            target='_blank'
            rel='noreferrer'
          >
            <IcoQuestionCircleLine color={color[COLOR.PURPLE][500]} />
            &nbsp;
            {domains?.length > 0 ? '도메인 연동 방법 보기' : '도메인 구매 및 연동 방법 보기'}
          </a>
        </DescriptionWrapper>
        {shouldShowButtons && (
          <ButtonWrapper>
            <PurpleButton onClick={handlePurchaseButtonClick}>새 도메인 구매하기</PurpleButton>
            <GrayButton onClick={handleConnectButtonClick}>내 도메인 연결하기</GrayButton>
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </MainHeader>
  );
}

DomainMainHeader.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object),
  onPurchaseButtonClick: PropTypes.func.isRequired,
  onConnectButtonClick: PropTypes.func.isRequired,
  isTrial: PropTypes.bool.isRequired,
};

DomainMainHeader.defaultProps = {
  domains: null,
};
