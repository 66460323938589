import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { color, COLOR } from 'styles/colors';
import useMemberInfo from 'hooks/useMemberInfo';
import { PATH } from 'constants/path';
import IcoDomainBuy from 'icons/IcoDomainBuy';
import IcoDomainLink from 'icons/IcoDomainLink';
import { PurpleButton, GrayButton } from 'components/atoms/Button';
import MyDomainListItem from 'components/molecules/MyDomainListItem';
import DomainMainHeader from 'components/organisms/DomainMainHeader';
import DomainInputModal from 'components/organisms/DomainInputModal';
import Card from 'components/wrappers/Card';
import DomainDetailsModal from 'components/terrains/DomainDetailsModal';
import { useDomainExtensionStore } from 'stores/domain';

const BodyWrapper = styled.div`
  position: relative;
  margin-top: 25px;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

const MainTextWrapper = styled.div`
  margin-bottom: 15px;
  font-size: 20px;
`;

const SubTextWrapper = styled.div`
  width: 420px;
  margin-bottom: 25px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 14px;
  text-align: center;
`;

const Trial = styled.div`
  margin-top: 200px;
  color: #8b8b9e;
  font-size: 24px;
  text-align: center;
`;

export default function DomainMain(props) {
  const { domains } = props;
  const { path } = useRouteMatch();
  const history = useHistory();
  const memberInfo = useMemberInfo();
  const setExtendingDomain = useDomainExtensionStore((store) => store.setExtendingDomain);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [currentDomain, setCurrentDomain] = useState(null);
  const isTrial = memberInfo?.plan === 'trial';
  const selections = [
    {
      type: 'purchase',
      onClick: handlePurchaseFirstDomainClick,
      mainText: '신규 도메인을 구매할 수 있어요.',
      subText1: '식스샵에서 구매한 도메인은',
      subText2: '별도의 추가 설정없이 웹사이트와 자동 연결됩니다.',
      buttonText: '구매하기',
    },
    {
      type: 'connect',
      onClick: handleConnectFirstDomainClick,
      mainText: '보유 중인 도메인을 연결할 수 있어요.',
      subText1: '타사에서 구입한 도메인은 네임서버 설정 작업을 완료해야 합니다.',
      subText2: '연결 방법은 안내서를 참고해 주세요.',
      buttonText: '연결하기',
    },
  ];

  function handleDomainPurchaseClick() {
    history.push(`${path}${PATH.NEW}${PATH.SEARCH}`);
  }

  function handlePurchaseFirstDomainClick(event) {
    event.stopPropagation();
    handleDomainPurchaseClick();
  }

  function handleConnectDomainClick() {
    setIsInputModalOpen(true);
  }

  function handleConnectFirstDomainClick(event) {
    event.stopPropagation();
    handleConnectDomainClick(true);
  }

  return (
    <>
      <DomainMainHeader
        domains={domains}
        onPurchaseButtonClick={handleDomainPurchaseClick}
        onConnectButtonClick={handleConnectDomainClick}
        isTrial={isTrial}
      />
      {renderDomainMainBody({
        memberInfo,
        domains,
        isTrial,
        selections,
        setCurrentDomain,
        setExtendingDomain,
      })}
      {isInputModalOpen && (
        <DomainInputModal
          isOpen={isInputModalOpen}
          setIsOpen={setIsInputModalOpen}
          isPending={isRequestPending}
          setIsPending={setIsRequestPending}
        />
      )}
      {currentDomain && (
        <DomainDetailsModal
          domainInfo={currentDomain}
          isOpen={!!currentDomain}
          setIsOpen={setCurrentDomain}
        />
      )}
    </>
  );
}

function renderDomainMainBody({
  memberInfo,
  domains,
  isTrial,
  selections,
  setCurrentDomain,
  setExtendingDomain,
}) {
  const shouldRenderBody = memberInfo && domains;
  const shouldShowContent = !isTrial || domains?.length > 0;

  if (!shouldRenderBody) {
    return <BodyWrapper />;
  }

  function handleCloseSettingsModal() {
    setCurrentDomain(null);
  }

  return (
    <BodyWrapper>
      {!shouldShowContent && <Trial>체험판에서는 도메인을 추가할 수 없습니다.</Trial>}
      {shouldShowContent &&
        (domains?.length
          ? domains.map((domainInfo) => {
              function handleSettingsClick() {
                setCurrentDomain(domainInfo);
              }

              function handleExtendClick() {
                setExtendingDomain({
                  domainNo: domainInfo.domainNo,
                  domainAddress: domainInfo.domainAddress,
                });
              }

              return (
                <MyDomainListItem
                  key={domainInfo.domainNo + domainInfo.domainAddress}
                  domainInfo={domainInfo}
                  onSettingsClick={handleSettingsClick}
                  closeSettingsModal={handleCloseSettingsModal}
                  onExtendClick={handleExtendClick}
                />
              );
            })
          : renderCards(selections))}
    </BodyWrapper>
  );
}

function renderCards(selections) {
  return (
    <CardsWrapper>
      {selections.map((selection) => (
        <Card key={selection.type} onClick={selection.onClick}>
          <IconWrapper>
            {selection.type === 'purchase' ? <IcoDomainBuy /> : <IcoDomainLink />}
          </IconWrapper>
          <MainTextWrapper>{selection.mainText}</MainTextWrapper>
          <SubTextWrapper>
            {selection.subText1}
            <br />
            {selection.subText2}
          </SubTextWrapper>
          {selection.type === 'purchase' ? (
            <PurpleButton width='145px' onClick={selection.onClick}>
              {selection.buttonText}
            </PurpleButton>
          ) : (
            <GrayButton width='145px' onClick={selection.onClick}>
              {selection.buttonText}
            </GrayButton>
          )}
        </Card>
      ))}
    </CardsWrapper>
  );
}

DomainMain.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object),
};

DomainMain.defaultProps = {
  domains: null,
};
