import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';

const Card = styled(PointerOnHoverWrapper)`
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  border: 1px solid ${color[COLOR.GRAY][300]};
  border-radius: 20px;
`;

export default Card;
