import { METHOD } from 'constants/method';

export default async function requestDomainExtendPaymentData(data) {
  const res = await fetch('/apis/v2/domains/orders/extension', {
    method: METHOD.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return res.json();
}
