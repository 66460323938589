import requestLogout from 'api/member/requestLogout';
import { RESPONSE_RESULT } from 'constants/responseResult';

export default async function handleDnsRemoveResponse(res, setAlertMessage) {
  const result = res.RESULT;

  if (result === RESPONSE_RESULT.NOT_LOGIN_ERROR) {
    await requestLogout();
    window.location.assign('/member/login');

    return;
  }

  if (result === RESPONSE_RESULT.OK) {
    return;
  }

  if (result.contains('UnsupportedCharacter')) {
    setAlertMessage('허용되지 않는 글자가 포함되어 있습니다.');
  } else {
    setAlertMessage(
      `정상적으로 삭제되지 않았습니다.\n문제가 지속되는 경우, 아래 메시지를 캡처하여 고객센터로 보내주시면 안내해드리겠습니다.\n\n'${result}`
    );
  }
}
