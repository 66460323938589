export default function ChevronRight() {
  return (
    <svg width={10} height={10} viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 7l3-3-3-3'
        stroke='#B9B9C5'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
