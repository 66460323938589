export default function IcoDomainBuy() {
  return (
    <svg width={50} height={50} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.192 14.249A.75.75 0 016.75 14h31.508a1.75 1.75 0 011.738 1.95l-2.88 24.933a2.75 2.75 0 01-2.732 2.434H11.523a2.75 2.75 0 01-2.735-2.458L6.004 14.83a.75.75 0 01.188-.58zM7.585 15.5l2.695 25.2a1.25 1.25 0 001.243 1.117h22.86a1.25 1.25 0 001.243-1.107l2.88-24.931a.25.25 0 00-.248-.279H7.584z'
        fill='#222'
      />
      <circle cx={36} cy={37} r={10} fill='#fff' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.35 46.075V40.65h-3.275c.261 1.214.646 2.288 1.112 3.163.697 1.306 1.469 2 2.163 2.262zm1.3-5.425v5.425c.694-.262 1.466-.956 2.163-2.262.466-.875.851-1.949 1.112-3.163H36.65zm-1.3 6.83v.02h1.3v-.02c5.496-.335 9.85-4.9 9.85-10.48s-4.354-10.145-9.85-10.48v-.02h-1.3v.02c-5.496.335-9.85 4.9-9.85 10.48s4.354 10.145 9.85 10.48zm1.3-19.555v5.425h3.275c-.261-1.214-.646-2.288-1.112-3.163-.697-1.306-1.469-2-2.163-2.262zm0 6.725h3.499c.098.747.151 1.534.151 2.35 0 .816-.053 1.603-.151 2.35H36.65v-4.7zm-1.3-6.725c-.694.262-1.466.956-2.163 2.262-.466.875-.851 1.949-1.112 3.163h3.275v-5.425zm0 6.725h-3.499A18.082 18.082 0 0031.7 37c0 .816.053 1.603.151 2.35h3.499v-4.7zm3.839 10.982c.934-1.215 1.662-2.95 2.063-4.982h3.195a9.23 9.23 0 01-5.258 4.982zm2.27-6.282h3.438c.198-.75.303-1.538.303-2.35s-.105-1.6-.303-2.35H41.46c.092.756.141 1.542.141 2.35s-.049 1.594-.14 2.35zm-14.356 0h3.438A19.446 19.446 0 0130.4 37c0-.808.049-1.594.14-2.35h-3.437A9.215 9.215 0 0026.8 37c0 .812.105 1.6.303 2.35zm3.645 1.3h-3.196a9.23 9.23 0 005.259 4.982c-.934-1.215-1.662-2.95-2.063-4.982zm13.7-7.3h-3.196c-.401-2.032-1.129-3.767-2.063-4.982a9.23 9.23 0 015.258 4.982zm-13.7 0c.401-2.032 1.129-3.767 2.063-4.982a9.23 9.23 0 00-5.259 4.982h3.196z'
        fill='#222'
      />
      <path
        d='M16 20v-9c0-4.418 3.358-8 7.5-8 4.142 0 7.5 3.582 7.5 8v9'
        stroke='#000'
        strokeWidth={1.5}
        strokeLinecap='round'
      />
    </svg>
  );
}
