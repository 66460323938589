import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import IcoCheckLine from 'icons/IcoCheckLine';
import Input from 'components/atoms/Input';
import FlexBox from 'components/wrappers/FlexBox';

const Wrapper = styled(FlexBox)`
  position: relative;
`;

export const Title = styled(FlexBox)`
  min-width: 170px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const CheckLineWrapper = styled(FlexBox)`
  position: absolute;
  right: 135px;
`;

export default function InputWithTitleOnSide(props) {
  const {
    title,
    inputWidth,
    inputMaxWidth,
    inputPlaceholder,
    inputValue,
    onInputChange,
    onInputKeyPress,
    onBlur,
    isInputDisabled,
    isChecked,
    children,
  } = props;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Input
        $width={inputWidth}
        maxWidth={inputMaxWidth}
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={onInputChange}
        onKeyPress={onInputKeyPress}
        onBlur={onBlur}
        disabled={isInputDisabled}
      />
      {isChecked && (
        <CheckLineWrapper>
          <IcoCheckLine color={color[COLOR.GREEN][500]} />
        </CheckLineWrapper>
      )}
      {children}
    </Wrapper>
  );
}

InputWithTitleOnSide.propTypes = {
  title: PropTypes.string,
  inputWidth: PropTypes.string,
  inputMaxWidth: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  onInputKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  isInputDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  children: PropTypes.node,
};

InputWithTitleOnSide.defaultProps = {
  title: '',
  inputWidth: '',
  inputMaxWidth: '550px',
  inputPlaceholder: '',
  inputValue: '',
  onInputChange: () => {},
  onInputKeyPress: () => {},
  onBlur: () => {},
  isInputDisabled: false,
  isChecked: false,
  children: null,
};
