import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';

import handleDnsModificationResponse from 'services/domain/handleDnsModificationResponse';
import handleDnsRemoveResponse from 'services/domain/handleDnsRemoveResponse';
import { QUERY_KEY } from 'constants/queryKey';
import { color, COLOR } from 'styles/colors';
import requestDeleteDnsRecord from 'api/domain/requestDeleteDnsRecord';
import requestChangeDnsRecord from 'api/domain/requestChangeDnsRecord';
import IcoDeleteGray from 'icons/IcoDelete';
import Input from 'components/atoms/Input';
import Textarea from 'components/atoms/Textarea';

const Row = styled.div`
  display: grid;
  grid-gap: 7px;
  grid-template-columns: 1fr 1fr 5fr 75px;

  span {
    padding: 15px;
  }
`;

const NameInput = styled(Input)`
  padding: 15px;
`;

const ValueTextarea = styled(Textarea)`
  padding: 15px;
`;

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${color[COLOR.GRAY][500]};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: ${color[COLOR.GRAY][400]};
  }
`;

export default function DnsRecordRow(props) {
  const { record, domainAddress, setAlertMessage } = props;
  const { type, name: prevName, resourceRecords } = record;
  const strippedPrevName = getDnsNameOnly(prevName, domainAddress);

  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [isMouseOverName, setIsMouseOverName] = useState(false);
  const [isMouseOverValue, setIsMouseOverValue] = useState(false);
  const [isMouseOverDelete, setIsMouseOverDelete] = useState(false);
  const queryClient = useQueryClient();

  let prevValue = resourceRecords[0]?.value;

  for (let i = 1; i < resourceRecords.length; i++) {
    prevValue += `\n${resourceRecords[i].value}`;
  }

  const { mutate: removeDnsRecord } = useMutation(
    () => requestDeleteDnsRecord(domainAddress, type, name),
    {
      onSucess: (data) => {
        handleDnsRemoveResponse(data, setAlertMessage);
        queryClient.invalidateQueries(QUERY_KEY.DOMAIN_DETAILS);
      },
      onError: () => {
        queryClient.invalidateQueries(QUERY_KEY.DOMAIN_DETAILS);
      },
    }
  );
  const { mutate: changeDnsRecord } = useMutation(
    () =>
      requestChangeDnsRecord(
        domainAddress,
        { type, name: getDnsNameOnly(prevName, domainAddress), value: prevValue },
        { type, name, value }
      ),
    {
      onSettled: (data) => {
        handleDnsModificationResponse(data, setAlertMessage);
        queryClient.invalidateQueries(QUERY_KEY.DOMAIN_DETAILS);
      },
    }
  );

  let isReadOnly = false;

  if (
    (type === 'A' && strippedPrevName === '') ||
    (type === 'A' && strippedPrevName === 'www') ||
    (type === 'CNAME' && prevValue.indexOf('.acm-validations.aws') !== -1)
  ) {
    isReadOnly = true;
  }

  useEffect(() => {
    setName(strippedPrevName);
    setValue(prevValue);
  }, []);

  function handleDeleteDnsRecord() {
    setAlertMessage('');

    if (
      /* eslint-disable */
      !window.confirm(
        `${type} 레코드를 정말로 삭제하시겠습니까?\n\n - ${name && `${name}.`}${domainAddress} `
      )
    ) {
      return;
    }

    removeDnsRecord();
  }

  function handleChangeDnsBlur() {
    const regIpv4 =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!value) {
      setAlertMessage('값을 입력해주세요.');

      return;
    }

    if (type === 'A' && !regIpv4.test(value)) {
      setAlertMessage('IPv4 형태로 입력해주세요.');

      return;
    }

    if (type === 'TXT' && (value.charAt(0) !== '"' || value.charAt(value.length - 1) !== '"')) {
      setAlertMessage('TXT 값은 쌍따옴표(")로 시작하고 "로 끝나야 합니다.');

      return;
    }

    if (type === 'CNAME' && value) {
      setAlertMessage('CNAME에는 IP를 입력할 수 없습니다.');

      return;
    }

    setAlertMessage('');
    changeDnsRecord();
  }

  function handleMouseOverName() {
    setIsMouseOverName(true);
  }

  function handleMouseOutName() {
    setIsMouseOverName(false);
  }

  function handleMouseOverValue() {
    setIsMouseOverValue(true);
  }

  function handleMouseOutValue() {
    setIsMouseOverValue(false);
  }

  function handleMouseOverDelete() {
    setIsMouseOverDelete(true);
  }

  function handleMouseOutDelete() {
    setIsMouseOverDelete(false);
  }

  return (
    <Row>
      <span>{type}</span>
      {isReadOnly ? (
        <>
          <NameInput value={getDnsNameOnly(name, domainAddress)} border='none' readOnly />
          <ValueTextarea rows={1} value={value} border='none' readOnly />
        </>
      ) : (
        <>
          <NameInput
            value={name
              .replace(`.${domainAddress}.`, '')
              .replace(`.${domainAddress}`, '')
              .replace(`${domainAddress}.`, '')
              .replace(domainAddress, '')
              .replace('\\100', '@')}
            onChange={(event) => setName(event.target.value)}
            onBlur={handleChangeDnsBlur}
            onMouseOver={handleMouseOverName}
            onMouseOut={handleMouseOutName}
            border={`1px solid ${isMouseOverName ? color[COLOR.GRAY][300] : color[COLOR.WHITE]}`}
          />
          <ValueTextarea
            rows={1}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onBlur={handleChangeDnsBlur}
            onMouseOver={handleMouseOverValue}
            onMouseOut={handleMouseOutValue}
            border={`1px solid ${isMouseOverValue ? color[COLOR.GRAY][300] : color[COLOR.WHITE]}`}
          />
          <DeleteContainer
            onMouseOver={handleMouseOverDelete}
            onMouseOut={handleMouseOutDelete}
            onClick={handleDeleteDnsRecord}
          >
            <IcoDeleteGray
              color={isMouseOverDelete ? color[COLOR.GRAY][400] : color[COLOR.GRAY][500]}
            />
            삭제하기
          </DeleteContainer>
        </>
      )}
    </Row>
  );
}

function getDnsNameOnly(name, domainAddress) {
  return name
    .replace(`.${domainAddress}.`, '')
    .replace(`.${domainAddress}`, '')
    .replace(`${domainAddress}.`, '')
    .replace(domainAddress, '')
    .replace('\\100', '@');
}

DnsRecordRow.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    resourceRecords: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  domainAddress: PropTypes.string.isRequired,
  setAlertMessage: PropTypes.func,
};

DnsRecordRow.defaultProps = {
  setAlertMessage: () => {},
};
