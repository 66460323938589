import requestLogout from 'api/member/requestLogout';

export default async function handleDnsModificationResponse(res, setAlertMessage) {
  if (!res) {
    setAlertMessage('오류가 발생했습니다.');

    return;
  }

  const result = res.RESULT;
  const alertMessageMap = {
    ALREADY_EXIST_DNS_NAME: '이미 등록된 DNS 레코드입니다.',
    ALREADY_EXIST_DNS_NAME_A: '해당 레코드는 이미 A 레코드에 등록되어 있습니다.',
    ALREADY_EXIST_DNS_NAME_CNAME: '해당 레코드는 이미 CNAME에 등록되어 있습니다.',
    NOT_ALLOWED_DNS_NAME_BLANK: 'CNAME의 Name에는 공백을 입력할 수 없습니다.',
    INVALID_VALUE_ERROR: '입력값이 적절하지 않습니다.',
  };

  if (result === 'NOT_LOGIN_ERROR') {
    await requestLogout();
    window.location.assign('/member/login');

    return;
  }

  if (alertMessageMap[result]) {
    setAlertMessage(alertMessageMap[result]);

    return;
  }

  if (result === 'OK') {
    return;
  }

  if (result.contains('UnsupportedCharacter')) {
    setAlertMessage('허용되지 않는 글자가 포함되어 있습니다.');

    return;
  }

  if (result.contains('already exists')) {
    setAlertMessage('이미 등록된 DNS 레코드입니다.');

    return;
  }

  if (res.dnsType === 'MX' && result.contains('Duplicate Resource Record')) {
    setAlertMessage('MX 레코드에 중복된 주소가 있습니다.');
  } else {
    setAlertMessage(
      `정상적으로 등록되지 않았습니다./n입력값에 문제가 없는지 확인해주세요./n문제가 지속되는 경우, 아래 메시지를 캡처하여 고객센터로 보내주시면 안내해드리겠습니다.\n\n${result}`
    );
  }
}
