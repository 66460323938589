import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { color, COLOR } from 'styles/colors';
import { PRICE, GABIA_SEARCH_RESULT_CODE, ALERT_MESSAGE } from 'constants/domain';
import { ALERT_TYPE } from 'constants/alertType';
import IcoSearchLine from 'icons/IcoSearchLine';
import DomainIcon from 'icons/DomainIcon';
import IcoWrongGray from 'icons/IcoWrongGray';
import requestSearchDomains from 'api/domain/requestSearchDomains';
import sortSearchResults from 'utils/domain/sortDomainSearchResults';
import validateDomainName from 'utils/domain/validateDomainName';
import trimHyphens from 'utils/domain/trimHyphens';
import { ShortWidePurpleButton, ShortWideGrayButton } from 'components/atoms/Button';
import Alert from 'components/atoms/Alert';
import Input from 'components/atoms/Input';
import HeadlineHeader from 'components/molecules/HeadlineHeader';
import {
  ContentsListItemWrapper,
  ThumbnailWrapper,
  ContentWrapper,
  MainTextWrapper,
  SubTextWrapper,
  ButtonWrapper,
} from 'components/wrappers/ContentsListItem';
import PointerOnHoverWrapper from 'components/wrappers/PointerOnHoverWrapper';
import BodyWrapper from 'components/wrappers/BodyWrapperWithSectionDividers';
import ColorWrapper from 'components/wrappers/ColorWrapper';
import FlexBox from 'components/wrappers/FlexBox';
import DetailPageLayout from 'layouts/DetailPageLayout';
import { useNewDomainStore } from 'stores/domain';

const SearchInputWrapper = styled(FlexBox)`
  height: 150px;
`;

const WwwWrapper = styled.span`
  margin-right: 5px;
`;

const InputWrapper = styled(FlexBox)`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding: 15px 40px 15px 15px;
`;

const SearchIconWrapper = styled(PointerOnHoverWrapper)`
  position: absolute;
  right: 16px;
`;

const TableHeader = styled(FlexBox)`
  flex: 1;
  height: 51px;
  color: ${color[COLOR.GRAY][500]};
  font-size: 14px;
  border-bottom: 1px solid ${color[COLOR.GRAY][200]};
`;

const DomainAddressTh = styled.div`
  flex: 2;
`;

const PriceTh = styled.div`
  flex: 1;
`;

const PriceWrapper = styled.div`
  flex: ${({ flex }) => flex};
  font-size: 15px;
`;

export default function DomainSearch() {
  const setSelectedDomain = useNewDomainStore((store) => store.setSelectedDomain);
  const [input, setInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [sortedSearchResults, setSortedSearchResults] = useState([]);
  const [alertType, setAlertType] = useState(ALERT_TYPE.ERROR);
  const [alertMessage, setAlertMessage] = useState(ALERT_MESSAGE.EMPTY_STRING);
  const history = useHistory();

  useEffect(() => {
    if (!searchResults.length) return;

    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);

    const sorted = sortSearchResults(searchResults);
    setSortedSearchResults(sorted);
  }, [searchResults]);

  function handleInputChange(event) {
    setInput(event.target.value);
  }

  async function handleSearchDomains() {
    const domainValidationResult = validateDomainName(input);

    if (!domainValidationResult.ok) {
      setAlertType(ALERT_TYPE.ERROR);
      setAlertMessage(domainValidationResult.message);

      return;
    }

    setAlertType(ALERT_TYPE.SUCCESS);
    setAlertMessage(ALERT_MESSAGE.SEARCHING);

    try {
      const response = await requestSearchDomains(
        trimHyphens(domainValidationResult.domainName).toLowerCase()
      );
      setSearchResults(response);
    } catch (error) {
      setAlertType(ALERT_TYPE.ERROR);
      setAlertMessage(ALERT_MESSAGE[error.message]);
      setSearchResults([]);
    }
  }

  function closeAlert() {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);
  }

  return (
    <DetailPageLayout>
      {alertMessage && (
        <Alert
          type={alertType}
          text={alertMessage}
          top='0'
          fontSize='16px'
          padding='15px'
          closeFunction={closeAlert}
        />
      )}
      <HeadlineHeader title='도메인 구매하기'>
        &#8251; 식스샵은 COM, NET, KR 도메인을 제공합니다.
        <br />
        &#8251; COM, NET, KR 도메인은 신규 등록 후 2일 이내로 취소할 수 있으며 전액 환불 처리됩니다.
      </HeadlineHeader>
      <BodyWrapper>
        <SearchInputWrapper>
          <WwwWrapper>www.</WwwWrapper>
          <InputWrapper>
            <StyledInput
              $width='346px'
              placeholder='사용하고자 하는 도메인을 입력하세요.'
              value={input}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleSearchDomains();
                }
              }}
              autoFocus
            />
            <SearchIconWrapper onClick={handleSearchDomains}>
              <IcoSearchLine />
            </SearchIconWrapper>
          </InputWrapper>
        </SearchInputWrapper>
        {searchResults.length > 0 && (
          <div>
            <TableHeader>
              <DomainAddressTh>도메인 주소</DomainAddressTh>
              <PriceTh>가격</PriceTh>
            </TableHeader>
            {sortedSearchResults.map((result, index) => {
              const domainAddress = result.domain_name;
              const isAvailable = result.code === GABIA_SEARCH_RESULT_CODE.AVAILABLE;
              const isRegistered = result.code === GABIA_SEARCH_RESULT_CODE.REGISTERED;
              const message = result.msg;

              function handlePurchaseClick() {
                setSelectedDomain(domainAddress);
                history.push('/store-setting/domain/new/register');
              }

              return (
                <ContentsListItemWrapper
                  /* eslint-disable */
                  key={`${domainAddress}${index}`}
                  borderColor={color[COLOR.GRAY][200]}
                >
                  <ThumbnailWrapper>
                    {isAvailable ? <DomainIcon /> : <IcoWrongGray />}
                  </ThumbnailWrapper>
                  <ContentWrapper flex={3.2}>
                    {isAvailable ? (
                      <MainTextWrapper>{domainAddress}</MainTextWrapper>
                    ) : (
                      <>
                        <MainTextWrapper>
                          <ColorWrapper color={color[COLOR.GRAY][500]}>
                            {domainAddress}
                          </ColorWrapper>
                        </MainTextWrapper>
                        <SubTextWrapper>
                          {isRegistered ? '이미 등록된 도메인입니다.' : message}
                        </SubTextWrapper>
                      </>
                    )}
                  </ContentWrapper>
                  <PriceWrapper flex={1}>
                    {isAvailable ? (
                      `${PRICE.toLocaleString()}원`
                    ) : (
                      <ColorWrapper color={color[COLOR.GRAY][500]}>-</ColorWrapper>
                    )}
                  </PriceWrapper>
                  <ButtonWrapper>
                    {isAvailable ? (
                      <ShortWidePurpleButton onClick={handlePurchaseClick}>
                        구매하기
                      </ShortWidePurpleButton>
                    ) : (
                      <ShortWideGrayButton disabled>구매하기</ShortWideGrayButton>
                    )}
                  </ButtonWrapper>
                </ContentsListItemWrapper>
              );
            })}
          </div>
        )}
      </BodyWrapper>
    </DetailPageLayout>
  );
}
