import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { color, COLOR } from 'styles/colors';
import { PRICE, ALERT_MESSAGE } from 'constants/domain';
import requestDomainExtendPaymentData from 'api/domain/requestDomainExtendPaymentData';
import IcoPlusCircleBig from 'icons/IcoPlusCircleBig';
import IcoEqualsCircleBig from 'icons/IcoEqualsCircleBig';
import Checkbox from 'components/atoms/Checkbox';
import Alert from 'components/atoms/Alert';
import HeadlineHeader from 'components/molecules/HeadlineHeader';
import Select from 'components/molecules/Select';
import Table from 'components/molecules/Table';
import Section from 'components/molecules/Section';
import TossPayments from 'components/organisms/TossPayments';
import BodyWrapperWithSectionDividers from 'components/wrappers/BodyWrapperWithSectionDividers';
import ColorWrapper from 'components/wrappers/ColorWrapper';
import FlexBox from 'components/wrappers/FlexBox';
import DetailPageLayout from 'layouts/DetailPageLayout';
import { useDomainExtensionStore } from 'stores/domain';

const AgreementsWrapper = styled(FlexBox)`
  margin-bottom: 10px;
`;

const NoCancelWrapper = styled.div`
  margin-bottom: 30px;
`;

const PriceSummaryWrapper = styled(FlexBox)`
  justify-content: flex-end;
  padding: 15px 0;
`;

const PriceSummaryElement = styled(FlexBox)`
  font-size: 18px;
`;

const PriceSummaryDescription = styled(ColorWrapper)`
  margin-right: 6px;
`;

const PriceSummaryIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin: 0 15px;
`;

const FinalPrice = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
`;

const TableRow = styled(FlexBox)`
  padding: 10px 0;
  font-size: 15px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: ${({ flex }) => flex};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export default function DomainExtendRegistration() {
  const history = useHistory();
  const { origin } = window.location;
  const domainData = useDomainExtensionStore((store) => store.extendingDomain);
  const period = useDomainExtensionStore((store) => store.period);
  const setPeriod = useDomainExtensionStore((store) => store.setPeriod);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isNoCancelChecked, setIsNoCancelChecked] = useState(false);

  useEffect(() => {
    if (!domainData) {
      history.push('/store-setting/domain');
    }
  }, []);

  function closeAlert() {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);
  }

  function handleAgreementsCheckboxChange() {
    setIsAgreementsChecked(!isAgreementsChecked);
  }

  function handleNoCancelCheckboxChange() {
    setIsNoCancelChecked(!isNoCancelChecked);
  }

  function validateAllCheckboxesChecked() {
    setAlertMessage(ALERT_MESSAGE.EMPTY_STRING);

    if (!isAgreementsChecked) {
      setAlertMessage(ALERT_MESSAGE.CHECK_AGREEMENTS);

      return false;
    }

    if (!isNoCancelChecked) {
      setAlertMessage(ALERT_MESSAGE.CHECK_NO_CANCEL_EXTEND_POLICY);

      return false;
    }

    return true;
  }

  function requestPaymentData() {
    return requestDomainExtendPaymentData({
      domainId: domainData.domainNo,
      extensionPeriod: period,
      listPrice: PRICE,
    });
  }

  return (
    <DetailPageLayout>
      {alertMessage && (
        <Alert
          text={alertMessage}
          top='0'
          fontSize='16px'
          padding='15px'
          closeFunction={closeAlert}
        />
      )}
      <HeadlineHeader title='도메인 연장하기' />
      <BodyWrapperWithSectionDividers>
        <Section title='신청 정보'>
          <Table
            hasHeader
            columnsInfo={[
              { title: '도메인 주소', flex: 3, alignment: 'start' },
              { title: '이용기간', flex: 1, alignment: 'center' },
              { title: '가격', flex: 1, alignment: 'center' },
            ]}
          >
            <TableRow>
              <FlexWrapper flex={3}>{domainData?.domainAddress}</FlexWrapper>
              <FlexWrapper flex={1} justifyContent='center'>
                <Select
                  width='120px'
                  selected={`${period}년`}
                  options={['1년', '2년', '3년', '4년', '5년']}
                  setFunction={(newSelected) => {
                    setPeriod(parseInt(newSelected, 10));
                  }}
                />
              </FlexWrapper>
              <FlexWrapper flex={1} justifyContent='center'>
                {`${PRICE.toLocaleString()}원`}
              </FlexWrapper>
            </TableRow>
          </Table>
          <PriceSummaryWrapper>
            <PriceSummaryElement>
              <PriceSummaryDescription color={color[COLOR.GRAY][500]}>
                도메인 가격
              </PriceSummaryDescription>
              {`${PRICE.toLocaleString()}원`}
            </PriceSummaryElement>
            <PriceSummaryIconWrapper>
              <IcoPlusCircleBig color={color[COLOR.GRAY][500]} rotation={45} />
            </PriceSummaryIconWrapper>
            <PriceSummaryElement>
              <PriceSummaryDescription color={color[COLOR.GRAY][500]}>
                이용기간
              </PriceSummaryDescription>
              {period}년
              <PriceSummaryIconWrapper>
                <IcoEqualsCircleBig />
              </PriceSummaryIconWrapper>
            </PriceSummaryElement>
            <FinalPrice>{(PRICE * period).toLocaleString()}원</FinalPrice>
          </PriceSummaryWrapper>
        </Section>
        <Section>
          <AgreementsWrapper>
            <Checkbox isChecked={isAgreementsChecked} onChange={handleAgreementsCheckboxChange}>
              [필수] 도메인&nbsp;
              <a href='/domain-policy' target='_blank' rel='noreferrer'>
                이용약관
              </a>
              에 동의합니다.
            </Checkbox>
          </AgreementsWrapper>
          <NoCancelWrapper>
            <Checkbox isChecked={isNoCancelChecked} onChange={handleNoCancelCheckboxChange}>
              [필수] 도메인 기간 연장 결제 건은 환불이 불가합니다.
            </Checkbox>
          </NoCancelWrapper>
          {domainData && (
            <TossPayments
              preRequest={validateAllCheckboxesChecked}
              fetchPaymentData={requestPaymentData}
              successUrl={`${origin}/store-setting/domain/extend/extend-result/success`}
              failUrl={`${origin}/store-setting/domain/extend/extend-result/fail`}
            />
          )}
        </Section>
      </BodyWrapperWithSectionDividers>
    </DetailPageLayout>
  );
}

DomainExtendRegistration.propTypes = {
  domainData: PropTypes.shape({
    domainNo: PropTypes.number,
    domainAddress: PropTypes.string,
  }),
};

DomainExtendRegistration.defaultProps = {
  domainData: null,
};
