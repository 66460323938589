import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import { color, COLOR } from 'styles/colors';
import useAnimation from 'hooks/useAnimation';
import Modal from 'components/atoms/Modal';
import Overlay from 'components/atoms/Overlay';
import { LargeRedButton, LargeGrayButton } from 'components/atoms/Button';

const modalFadeIn = keyframes`
	from {
		opacity: 0;
		transform: translate(-50%, calc(-50% + 50px));
	}

	to {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
`;

const overlayFadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const ModalWrapper = styled.div`
  position: fixed;
  width: 500px;
  border-radius: 20px;
  text-align: left;
  padding: 0 30px;
  top: 50%;
  left: 50%;
  background-color: ${color[COLOR.WHITE]};
  opacity: 1;
  z-index: 19;
  animation: ${css`
    ${modalFadeIn} 300ms linear forwards;
  `};
`;

const PromptTextWrapper = styled.div`
  margin: 63px 0;
  color: ${color[COLOR.BLACK]};
`;

const MainMessage = styled.div`
  font-size: 22px;
  margin-bottom: 8px;
  text-align: center;
`;

const SubMessage = styled.div`
  font-size: 15px;
  color: ${color[COLOR.GRAY][500]};
  margin-bottom: 8px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0 30px;
  border-top: 1px solid ${color[COLOR.GRAY][200]};
`;

const AnimatedOverlay = styled(Overlay)`
  animation: ${css`
    ${overlayFadeIn} 300ms linear forwards;
  `};
`;

export default function PromptModal(props) {
  const { toClose, isOpen, mainMessage, subMessage, confirmText, cancelText, onConfirm } = props;
  const [shouldRender] = useAnimation(isOpen);

  function closeModal() {
    toClose();
  }

  return shouldRender ? (
    <>
      <ModalWrapper>
        <Modal>
          <PromptTextWrapper>
            <MainMessage>{mainMessage}</MainMessage>
            <SubMessage>{subMessage}</SubMessage>
          </PromptTextWrapper>
          <ButtonWrapper>
            <LargeRedButton onClick={onConfirm}>{confirmText}</LargeRedButton>
            <LargeGrayButton onClick={closeModal}>{cancelText}</LargeGrayButton>
          </ButtonWrapper>
        </Modal>
      </ModalWrapper>
      <AnimatedOverlay onClick={closeModal} isActive={isOpen} />
    </>
  ) : null;
}

PromptModal.propTypes = {
  toClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mainMessage: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

PromptModal.defaultProps = {
  subMessage: '',
};
