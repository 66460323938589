export default function trimHyphens(input) {
  if (input.startsWith('-')) {
    return trimHyphens(input.slice(1));
  }

  if (input.endsWith('-')) {
    return trimHyphens(input.slice(0, input.length - 1));
  }

  return input;
}
