export default function IcoEqualsCircleBig() {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 19a9 9 0 100-18 9 9 0 000 18zM5.787 8.363a.75.75 0 01.75-.75h6.923a.75.75 0 010 1.5H6.537a.75.75 0 01-.75-.75zm0 3.274a.75.75 0 01.75-.75h6.923a.75.75 0 010 1.5H6.537a.75.75 0 01-.75-.75z'
        fill='#222'
      />
    </svg>
  );
}
