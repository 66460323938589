import PropTypes from 'prop-types';

import { color as colors } from 'styles/colors';

export default function IcoCloseLineBig(props) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L10.0711 8.65685L16.435 2.29289C16.8256 1.90237 17.4587 1.90237 17.8492 2.29289C18.2398 2.68342 18.2398 3.31658 17.8492 3.70711L11.4853 10.0711L17.8492 16.435C18.2398 16.8256 18.2398 17.4587 17.8492 17.8492C17.4587 18.2398 16.8256 18.2398 16.435 17.8492L10.0711 11.4853L3.70711 17.8492C3.31658 18.2398 2.68342 18.2398 2.29289 17.8492C1.90237 17.4587 1.90237 16.8256 2.29289 16.435L8.65685 10.0711L2.29289 3.70711Z'
        fill={color}
      />
    </svg>
  );
}

IcoCloseLineBig.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

IcoCloseLineBig.defaultProps = {
  width: 18,
  height: 18,
  color: colors.black,
};
