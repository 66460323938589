import { METHOD } from 'constants/method';

export default async function requestChangeDnsRecord(domainAddress, prevDnsRecord, newDnsRecord) {
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const { id: memberNo } = authentication;

  const res = await fetch('/_domain/changeDns', {
    method: METHOD.POST,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      memberNo,
    },
    body: new URLSearchParams({
      memberNo,
      domainAddress,
      dnsType: newDnsRecord.type,
      dnsName: newDnsRecord.name,
      dnsValue: newDnsRecord.value,
      prevDnsName: prevDnsRecord.name,
      prevDnsValue: prevDnsRecord.value,
    }),
  });

  return res.json();
}
