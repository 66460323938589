import { METHOD } from 'constants/method';

export default async function requestVerificationCode(receiver) {
  const res = await fetch(
    `${process.env.REACT_APP_SIXSHOP2_USERHOME_SERVER_HOST}/domains/validation`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: METHOD.POST,
      body: JSON.stringify({ receiver }),
    }
  );

  return res.json();
}
