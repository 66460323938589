export default async function requestDomains() {
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const { id: memberNo } = authentication;

  const res = await fetch(
    `${process.env.REACT_APP_SIXSHOP2_USERHOME_SERVER_HOST}/domains/getDomainsByMemberNo?memberNo=${memberNo}`
  );

  return res.json();
}
