import { ALERT_MESSAGE } from 'constants/domain';

export default function validateInfo(
  isOwnersForm,
  setAlertMessage,
  koreanName,
  englishName,
  phoneNumber,
  isEmailVerified,
  verificationCode,
  address,
  isIndividual,
  registrationCertificateType,
  registrationCertificate,
  institutionKoreanName,
  institutionEnglishName
) {
  // eslint-disable-next-line
  const regexLatinCharOnly = /^[A-z\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-_`~()]+$/;
  // eslint-disable-next-line
  const regexLatinCharAndNumberOnly = /^[A-z\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-_`~()0-9]+$/;
  const regexKoreanOnly = /^[가-힣]+$/;
  const regexKoreanAndNumberOnly = /^[가-힣0-9]+$/;

  if (isOwnersForm && !isIndividual) {
    if (!registrationCertificateType) {
      setAlertMessage(ALERT_MESSAGE.CHECK_CERTIFICATE_TYPE);

      return false;
    }

    if (!registrationCertificate) {
      setAlertMessage(ALERT_MESSAGE.CHECK_CERTIFICATE);

      return false;
    }

    if (!validateRegistrationCertificate(registrationCertificate)) {
      setAlertMessage(ALERT_MESSAGE.CHECK_CERTIFICATE);

      return false;
    }

    if (!institutionKoreanName || !regexKoreanAndNumberOnly.test(institutionKoreanName)) {
      setAlertMessage(ALERT_MESSAGE.CHECK_INSTITUTION_KOREAN_NAME);

      return false;
    }

    if (!institutionEnglishName || !regexLatinCharAndNumberOnly.test(institutionEnglishName)) {
      setAlertMessage(ALERT_MESSAGE.CHECK_INSTITUTION_ENGLISH_NAME);

      return false;
    }
  }

  if (!koreanName) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.OWNER_KOREAN_NAME_NO_INPUT)
      : setAlertMessage(ALERT_MESSAGE.MANAGER_KOREAN_NAME_NO_INPUT);

    return false;
  }

  if (!regexKoreanOnly.test(koreanName) || koreanName.length < 2 || koreanName.length > 20) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_KOREAN_NAME)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_KOREAN_NAME);

    return false;
  }

  if (!englishName) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.OWNER_ENGLISH_NAME_NO_INPUT)
      : setAlertMessage(ALERT_MESSAGE.MANAGER_ENGLISH_NAME_NO_INPUT);

    return false;
  }

  if (!regexLatinCharOnly.test(englishName) || englishName.length < 2 || englishName.length > 20) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_ENGLISH_NAME)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_ENGLISH_NAME);

    return false;
  }

  if (
    !phoneNumber.head ||
    !phoneNumber.middle ||
    !phoneNumber.tail ||
    Number.isNaN(Number(phoneNumber.head)) ||
    Number.isNaN(Number(phoneNumber.middle)) ||
    Number.isNaN(Number(phoneNumber.tail)) ||
    phoneNumber.head.length < 2 ||
    phoneNumber.head.length > 4 ||
    !(phoneNumber.middle.length === 3 || phoneNumber.middle.length === 4) ||
    phoneNumber.tail.length !== 4
  ) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_PHONE_NUMBER)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_PHONE_NUMBER);

    return false;
  }

  // 이메일 인증코드를 받지 않았을 때
  if (!isEmailVerified && !verificationCode) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_EMAIL_CODE)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_EMAIL_CODE);

    return false;
  }

  // 이메일 인증코드를 받았지만 틀렸을 때
  if (!isEmailVerified) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_VERIFICATION_CODE_INPUT)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_VERIFICATION_CODE_INPUT);

    return false;
  }

  if (!address.zipCode) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_ADDRESS)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_ADDRESS);

    return false;
  }

  if (!address.roadAddress) {
    isOwnersForm
      ? setAlertMessage(ALERT_MESSAGE.CHECK_OWNER_ROAD_ADDRESS)
      : setAlertMessage(ALERT_MESSAGE.CHECK_MANAGER_ROAD_ADDRESS);

    return false;
  }

  if (!address.additionalAddress) {
    setAlertMessage(ALERT_MESSAGE.CHECK_ADDITIONAL_ADDRESS);

    return false;
  }

  return true;
}

function validateRegistrationCertificate(input) {
  const hyphensStripped = input.replaceAll('-', '');

  if (hyphensStripped.length < 10 || hyphensStripped.length > 13) {
    return false;
  }

  return true;
}
