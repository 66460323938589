import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, COLOR } from 'styles/colors';
import IconChevronDownLine from 'icons/IcoChevronDownLine';
import FlexBox from 'components/wrappers/FlexBox';

const Container = styled.div`
  position: relative;
  z-index: 1;
  flex: ${({ flex }) => flex};
  width: ${({ $width }) => $width};
  cursor: pointer;
`;

const Main = styled(FlexBox)`
  justify-content: space-between;
  height: 50px;
  padding: 16px 15px 15px;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-size: ${({ $fontSize }) => $fontSize};
  border: 1px solid ${color[COLOR.GRAY][300]};
  border-radius: ${({ isOpen }) => (isOpen ? '10px 10px 0 0' : '10px')};
`;

const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${color[COLOR.WHITE]};
  border: 1px solid ${color[COLOR.GRAY][300]};
  border-top: none;
  border-radius: 0 0 10px 10px;
`;

const StyledUl = styled.ul`
  li:last-child {
    border-radius: 0 0 10px 10px;
  }
`;

const StyledLi = styled.li`
  display: list-item;
  padding: 13px;
  font-size: 13px;

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? null : '#EEE')};
  }
`;

export default function Select(props) {
  const { width, selected, options, setFunction, fontSize, fontWeight } = props;
  const [isOpen, setIsOpen] = useState(false);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleOptionClick(event) {
    setIsOpen(false);

    if (!event.target.getAttribute('value')) return;

    setFunction(event.target.getAttribute('value'));
  }

  return (
    <Container $width={width}>
      <Main onClick={toggleDropdown} isOpen={isOpen} $fontSize={fontSize} $fontWeight={fontWeight}>
        {selected || options[0]}
        <IconChevronDownLine color={color[COLOR.GRAY][400]} direction={isOpen ? 'up' : 'down'} />
      </Main>
      {isOpen && (
        <Dropdown onClick={handleOptionClick}>
          <StyledUl>
            {options.map((option) => (
              <StyledLi key={option} value={option} isSelected={option === selected}>
                {option}
              </StyledLi>
            ))}
          </StyledUl>
        </Dropdown>
      )}
    </Container>
  );
}

Select.propTypes = {
  width: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFunction: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
};

Select.defaultProps = {
  width: '',
  selected: '',
  fontSize: '13px',
  fontWeight: 600,
};
